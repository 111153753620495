// translate to javascript and custom it by Blueberry 03/02/2023
import * as React from "react";
import { Box } from "@mui/material";
import Header from "../../Header";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { DataGridPremium, GridActionsCellItem } from "@mui/x-data-grid-premium";

import DefaultToolbar from "./DefaultToolbar";
import { useEffect, useState } from "react";
import ShowAlert from "../../../utils/ShowAlert";
import { useNotificationHandling } from "../../../utils/NotificationHandling";
import useColors from "../../../hooks/use-colors";

function DataEntryForm({
  title,
  subtitle,
  columns = {
    columnVisibilityModel: {
      id: false,
    },
  },
  rows,
  onDeleteRow,
  filterKey,
  onAdd,
  onEdit,
  disableAdd,
  disableEdit,
  disableDelete,
  autoHeight = true,
  pagination = true,
  defaultPageSize = 25,
  rowsPerPageOptions = [5, 10, 25, 50, 100],
  getActions = () => {
    return [];
  },
  ...props
}) {
  const { colors, isDark } = useColors();
  const {
    notificationState,
    handleClose,
    handleSuccessNotification,
    handleErrorNotification,
  } = useNotificationHandling();
  const [internalRows, setInternalRows] = useState(rows);
  const [filterModel, setFilterModel] = useState(loadFilterModel());
  const [rowGroupingModel, setRowGroupingModel] = useState(loadGroupingModel());
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const handleEditClick = (id) => (e) => {
    if (props.loading) return;

    e.stopPropagation();
    onEdit(id);
  };

  const handleAddClick = () => {
    if (props.loading) return;

    onAdd();
  };

  const handleDeleteClick = async (id) => {
    const row = internalRows.find((r) => r.id === id);

    try {
      const deleteResponse = await onDeleteRow(id, row, internalRows);
      if (deleteResponse === "Deleted") {
        setInternalRows(internalRows.filter((row) => row.id !== id));
      }

      if (row.deleted_name) {
        handleSuccessNotification(`${row.deleted_name} deleted`);
      } else {
        handleSuccessNotification("Deleted");
      }
    } catch (error) {
      handleErrorNotification(error);
    }
  };
  const handleFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
  };

  function loadFilterModel() {
    const savedFilterModel = localStorage.getItem(`filterModel_${filterKey}`);

    if (savedFilterModel) {
      try {
        const parsedModel = JSON.parse(savedFilterModel);
        return parsedModel && parsedModel.items ? parsedModel : { items: [] };
      } catch (e) {
        return { items: [] };
      }
    }
    return { items: [] };
  }

  function loadGroupingModel() {
    const savedGroupModel = localStorage.getItem(`groupModel_${filterKey}`);

    if (savedGroupModel) {
      try {
        const parsedModel = JSON.parse(savedGroupModel);
        return parsedModel || [];
      } catch (e) {
        return [];
      }
    }
    return [];
  }

  const appendedColumns = [...columns];

  if (!disableEdit || !disableDelete) {
    const actionColumns = {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const items = [];
        if (getActions(row).length > 0) {
          getActions(row).forEach((action) => {
            items.push(
              <GridActionsCellItem
                icon={action.icon}
                label={action.label}
                onClick={() => action.onClick(id)}
                color="inherit"
              />
            );
          });
        }

        if (!disableEdit) {
          items.push(
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={handleEditClick(id)}
              color="inherit"
            />
          );
        }

        if (!disableDelete) {
          items.push(
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => handleDeleteClick(id)}
              color="inherit"
            />
          );
        }

        return items;
      },
    };
    appendedColumns.push(actionColumns);
  }

  function saveFilterModel(filterModel) {
    if (filterModel && filterModel.items) {
      localStorage.setItem(
        `filterModel_${filterKey}`,
        JSON.stringify(filterModel)
      );
    }
  }

  const saveGroupModel = (groupModel) => {
    localStorage.setItem(`groupModel_${filterKey}`, JSON.stringify(groupModel));
  };

  const handleGroupModelChange = (model, details) => {
    setRowGroupingModel(model);
  };

  useEffect(() => {
    setInternalRows(rows);
  }, [rows]);

  useEffect(() => {
    saveFilterModel(filterModel);

    return () => {
      saveFilterModel(filterModel); // Ensure filter model is saved when component unmounts
    };
  }, [filterModel]);

  useEffect(() => {
    saveGroupModel(rowGroupingModel);

    return () => {
      saveGroupModel(rowGroupingModel); // Ensure filter model is saved when component unmounts
    };
  }, [rowGroupingModel]);

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  return (
    <>
      <Box>
        <Header title={title} subtitle={subtitle} />

        <Box
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              fontWeight: 500,
            },
            "& .wrap-column--cell": {
              overflowwrap: "break-all",
              wordwrap: "break-all",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.secondary,
              color: "white",
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.tableBackground,
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.secondary,
              color: "white",
              borderRadius: "0 0 8px 8px",
              minHeight: "40px",
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: isDark
                ? `${colors.grey[100]} !important`
                : `${colors.grey[500]} !important`,
            },
          }}
        >
          <DataGridPremium
            rows={internalRows}
            columns={appendedColumns}
            autoHeight
            slots={{
              toolbar: DefaultToolbar,
            }}
            density="compact"
            disableVirtualization
            filterModel={filterModel}
            slotProps={{
              toolbar: {
                rows: internalRows,
                disableAdd: disableAdd,
                setRows: setInternalRows,
                columns,
                onAdd: handleAddClick,
              },
            }}
            rowGroupingModel={rowGroupingModel}
            onFilterModelChange={handleFilterModelChange}
            onRowGroupingModelChange={handleGroupModelChange}
            experimentalFeatures={{ newEditingApi: true }}
            pageSize={pageSize} //pagination
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            {...props}
          />
        </Box>
      </Box>
    </>
  );
}

export default DataEntryForm;
