import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  Grid,
  Typography,
  Box,
  InputLabel,
  Stack,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import {
  validateMultipleMeasures,
  validateTreatmentPlanSessionCoverage,
} from "../../../utils/data_validation/episodeStatus";

const createValidationSchema = (
  episodeOfCare,
  treatmentStatus,
  treatmentPlans,
  questionnaireScores
) => {
  return Yup.object().shape({
    patient_id: Yup.string().required("Patient is required"),
    office_id: Yup.string().required("Office is required"),
    practitioner_id: Yup.string(),
    treatment_status_id: Yup.string()
      .required("Status is required")
      .test("treatment-plan-validation", "", function (value) {
        const isCompletionStatus = treatmentStatus.some(
          (status) =>
            Number(status.treatment_status_id) === Number(value) &&
            status.treatment_status === "Completed"
        );
        if (episodeOfCare && isCompletionStatus) {
          const validationResult = validateTreatmentPlanSessionCoverage(
            treatmentPlans,
            episodeOfCare.acute_phase_sessions
          );

          if (!validationResult.valid) {
            return this.createError({
              message: validationResult.message,
            });
          }

          // Validate questionnaire scores
          const measures = ["Depression Severity", "Anxiety Severity"];
          const intervals = ["Baseline", "Final"];
          const questionnaireScoreValidationResult = validateMultipleMeasures(
            questionnaireScores,
            measures,
            intervals
          );

          if (!questionnaireScoreValidationResult.valid) {
            return this.createError({
              message: questionnaireScoreValidationResult.message,
            });
          }
        }
        return true;
      }),
    treatment_status_reason_id: Yup.string().nullable(),
    start_date: Yup.date()
      .required("Start date is required")
      .test(
        "is-not-future-date",
        "Start date cannot be in the future",
        function (value) {
          return value <= new Date();
        }
      ),
    end_date: Yup.date().when("treatment_status_id", {
      is: (treatment_status_id) => {
        const nonEndDateStatuses = treatmentStatus
          .filter((status) =>
            ["In-Progress", "Data Converted"].includes(status.treatment_status)
          )
          .map((status) => status.treatment_status_id);
        return !nonEndDateStatuses.includes(Number(treatment_status_id));
      },
      then: Yup.date()
        .required("End date is required")
        .test(
          "is-greater-than-start_date",
          "End date must be greater than or equal to Start date",
          function (value) {
            const { start_date } = this.parent;
            return value >= start_date;
          }
        )
        .test(
          "is-not-future-date",
          "End date cannot be in the future",
          function (value) {
            return value <= new Date();
          }
        ),
      otherwise: Yup.date()
        .nullable()
        .test(
          "is-date-not-defined",
          "End date should not be defined if status is 'In Progress' or 'Data Converted'",
          function (value) {
            return !value;
          }
        ),
    }),
  });
};

const TMSEpisodeForm = ({
  offices,
  practitioners,
  treatmentStatusReasons,
  patients,
  selectedEpisode,
  treatmentStatus,
  treatmentPlans,
  onCancel,
  onSubmit,
  open,
  onClose,
  isUpdate,
  questionnaireScores,
}) => {
  const showStatusReason = (values) => {
    const discontinuedStatus = treatmentStatus.find(
      (status) => status.treatment_status === "Discontinued"
    );
    return (
      discontinuedStatus &&
      Number(values.treatment_status_id) ===
        discontinuedStatus.treatment_status_id
    );
  };

  const showEndDate = (treatment_status_id) => {
    if (!treatment_status_id) return false;

    const nonEndDateStatuses = treatmentStatus
      .filter((status) =>
        ["In-Progress", "Data Converted"].includes(status.treatment_status)
      )
      .map((status) => status.treatment_status_id);

    return !nonEndDateStatuses.includes(Number(treatment_status_id));
  };

  const handleChangeTreatmentStatus = (e, setFieldValue, values) => {
    const newStatusId = e.target.value;

    // Set the new status
    setFieldValue("treatment_status_id", newStatusId, false); // Added false to prevent immediate validation
    setFieldValue("treatment_status_reason_id", "", false);

    if (!showEndDate(newStatusId)) {
      setFieldValue("end_date", "", false);
    }

    // Validate after all values are set
    setTimeout(() => {
      setFieldValue("treatment_status_id", newStatusId, true);
    }, 0);
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "750px",
        },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{`${
        isUpdate ? "Edit" : "Add"
      } TMS Episodes of Care`}</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            patient_id: selectedEpisode?.patient_id || "",
            office_id: selectedEpisode?.office_id || "",
            practitioner_id: selectedEpisode?.practitioner_id || "",
            start_date: selectedEpisode?.start_date || "",
            end_date: selectedEpisode?.end_date || "",
            treatment_status_id: selectedEpisode?.treatment_status_id || "",
            treatment_status_reason_id:
              selectedEpisode?.treatment_status_reason_id || "",
          }}
          validationSchema={createValidationSchema(
            selectedEpisode,
            treatmentStatus,
            treatmentPlans,
            questionnaireScores
          )}
          onSubmit={(values) => onSubmit(values)}
          validateOnChange={true}
        >
          {({
            errors,
            touched,
            handleChange,
            setFieldValue,
            values,
            onBlur,
          }) => (
            <Form>
              <Box
                sx={{
                  maxWidth: 750,
                  mx: "auto",
                  p: 3,
                  boxShadow: 3,
                  borderRadius: 2,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Autocomplete
                        slotProps={{
                          paper: {
                            sx: {
                              "& .MuiAutocomplete-listbox": {
                                zIndex: "9999",
                              },
                            },
                          },
                        }}
                        value={
                          patients.find(
                            (patient) => patient.id === values.patient_id
                          ) || null
                        }
                        disabled={Boolean(selectedEpisode?.patient_id)}
                        options={patients}
                        filterOptions={(options, state) => {
                          const inputValue = state.inputValue;
                          if (inputValue.length < 2) {
                            return [];
                          }
                          return options.filter((option) =>
                            option.full_name
                              .toLowerCase()
                              .includes(inputValue.toLowerCase())
                          );
                        }}
                        getOptionLabel={(option) => option.full_name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Patient"
                            placeholder="Type at least 2 letters to search patient..."
                          />
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(event, option) => {
                          if (option) {
                            setFieldValue("patient_id", option.id);
                          }
                        }}
                        renderOption={(props, option) => {
                          const { key, ...optionProps } = props;
                          return (
                            <Stack
                              key={option.id}
                              alignItems={`flex-start`}
                              justifyContent={`flex-start`}
                              sx={{
                                width: "100%",
                                padding: "5px",
                                paddingInline: "20px",
                                borderBottom: "1px solid #E1E1E1",
                              }}
                              {...optionProps}
                            >
                              <Typography width={`100%`} textAlign={`left`}>
                                {option.full_name}
                              </Typography>
                            </Stack>
                          );
                        }}
                      />
                      {touched.patient_id && errors.patient_id && (
                        <Typography color="error">
                          {errors.patient_id}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="location-label">Location</InputLabel>
                      <Select
                        label="Location"
                        labelId="location-label"
                        id="office"
                        name="office_id"
                        value={values.office_id}
                        onChange={(e) => {
                          setFieldValue("office_id", e.target.value);
                        }}
                        error={touched.office_id && Boolean(errors.office_id)}
                      >
                        {offices.map((office) => (
                          <MenuItem key={office.id} value={office.id}>
                            {`${office.name}`}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.office_id && errors.office_id && (
                        <Typography color="error">
                          {errors.office_id}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="practitioner-label">
                        Practitioner
                      </InputLabel>
                      <Select
                        label="Practitioner"
                        labelId="practitioner-label"
                        id="practitioner"
                        name="practitioner_id"
                        value={values.practitioner_id}
                        onChange={(e) =>
                          setFieldValue("practitioner_id", e.target.value)
                        }
                        error={
                          touched.practitioner_id &&
                          Boolean(errors.practitioner_id)
                        }
                      >
                        {practitioners.map((practitioner) => (
                          <MenuItem
                            key={practitioner.id}
                            value={practitioner.id}
                          >
                            {`${practitioner.full_name}`}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.practitioner_id && errors.practitioner_id && (
                        <Typography color="error">
                          {errors.practitioner_id}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      name="start_date"
                      as={TextField}
                      label="Start Date"
                      type="date"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={values.start_date}
                      onChange={handleChange}
                      error={touched.start_date && Boolean(errors.start_date)}
                      helperText={touched.start_date && errors.start_date}
                    />
                  </Grid>

                  {showEndDate(values.treatment_status_id) && (
                    <Grid item xs={12}>
                      <Field
                        name="end_date"
                        as={TextField}
                        label="End Date"
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={values.end_date}
                        onChange={handleChange}
                        error={touched.end_date && Boolean(errors.end_date)}
                        helperText={touched.end_date && errors.end_date}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="status-label">
                        Treatment Status
                      </InputLabel>
                      <Select
                        label="Treatment Status"
                        labelId="status-label"
                        id="status"
                        name="treatment_status_id"
                        value={values.treatment_status_id}
                        onChange={(e) =>
                          handleChangeTreatmentStatus(e, setFieldValue, values)
                        }
                        error={
                          touched.treatment_status_id &&
                          Boolean(errors.treatment_status_id)
                        }
                        onBlur={onBlur}
                      >
                        {treatmentStatus
                          .filter((t) => {
                            if (selectedEpisode?.id) {
                              return true;
                            }
                            return t.treatment_status === "In-Progress";
                          })
                          .map((status) => (
                            <MenuItem
                              key={status.id}
                              value={status.treatment_status_id}
                            >
                              {status.treatment_status}
                            </MenuItem>
                          ))}
                      </Select>
                      {touched.treatment_status_id &&
                        errors.treatment_status_id && (
                          <Typography color="error">
                            {errors.treatment_status_id}
                          </Typography>
                        )}
                    </FormControl>
                  </Grid>

                  {showStatusReason(values) && (
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="status-reason-label">
                          Status Reason
                        </InputLabel>
                        <Select
                          label="Status Reason"
                          labelId="status-reason-label"
                          id="status-reason"
                          name="treatment_status_reason_id"
                          value={values.treatment_status_reason_id}
                          onChange={(e) =>
                            setFieldValue(
                              "treatment_status_reason_id",
                              e.target.value
                            )
                          }
                          error={
                            touched.treatment_status_reason_id &&
                            Boolean(errors.treatment_status_reason_id)
                          }
                        >
                          {treatmentStatusReasons
                            .filter(
                              (reason) =>
                                reason.treatment_status_id ===
                                Number(values.treatment_status_id)
                            )
                            .map((reason) => (
                              <MenuItem
                                key={reason.treatment_status_reason_id}
                                value={reason.treatment_status_reason_id}
                              >
                                {`${reason.treatment_status_reason} | ${reason.description}`}
                              </MenuItem>
                            ))}
                        </Select>
                        {touched.treatment_status_reason_id &&
                          errors.treatment_status_reason_id && (
                            <Typography color="error">
                              {errors.treatment_status_reason_id}
                            </Typography>
                          )}
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item xs={6}>
                    <Button
                      type="button"
                      variant="outlined"
                      color="secondary"
                      fullWidth
                      onClick={onCancel}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default TMSEpisodeForm;
