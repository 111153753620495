import * as React from "react";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import GridExcelExportMenuItem from "../GridExcelExportMenuItem";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

function DefaultToolbar({columns, onAdd, disableAdd}) {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExportContainer>
        <GridExcelExportMenuItem columns={columns} />
        <GridCsvExportMenuItem />
      </GridToolbarExportContainer>
      <GridToolbarQuickFilter />
      {!disableAdd && (
        <Button color="primary" startIcon={<AddIcon />} onClick={onAdd}>
          Add record
        </Button>
      )}
    </GridToolbarContainer>
  );
}

export default DefaultToolbar;
