import axios from 'axios';
import { config } from '../config/Config';
import { getToken } from './AuthService';
import { HandleTokenError } from './TokenError';

async function makeRequest(method, options = {}) {
  const url = `${config.baseUrl}`;

  const token = await getToken();
  if (token instanceof Error) {
    return <HandleTokenError error={token} />;
  }

  const defaultHeaders = {
    Authorization: `Bearer ${token}`,
  };

  const response = await axios({
    method,
    url,
    headers: { ...defaultHeaders, ...options.headers },
    data: options.body,
    params: options.query_params,
  });

  //console.log(response);
  return response;
}

async function makeErrorRequest(method, options = {}) {
  const url = `${config.errorUrl}`;

  const token = await getToken();
  if (token instanceof Error) {
    return <HandleTokenError error={token} />;
  }

  const defaultHeaders = {
    Authorization: `Bearer ${token}`,
  };

  const response = await axios({
    method,
    url,
    headers: { ...defaultHeaders, ...options.headers },
    data: options.body,
    params: options.query_params,
  });

  return response;
}

async function makeUserRequest(method, options = {}) {
  const url = `${config.userUrl}`;

  const token = await getToken();
  if (token instanceof Error) {
    return <HandleTokenError error={token} />;
  }

  const defaultHeaders = {
    Authorization: `Bearer ${token}`,
  };

  const response = await axios({
    method,
    url,
    headers: { ...defaultHeaders, ...options.headers },
    data: options.body,
    params: options.query_params,
  });

  return response;
}

export async function getOne(table, query_params) {
  const params = { ...query_params, table: table };

  try {
    const response = await makeRequest('get', { query_params: params });

    if (!response.data.length) {
      const customError = new Error();
      customError.name = 'Data not found';
      customError.message =
        'No data was found that match your search parameters';
      throw customError;
    }

    return response.data[0];
  } catch (error) {
    throw error;
  }
}

export async function getDrchronoData(table, body) {
  try {
    const response = await makeRequest('post', table, { body });

    if (response.data.length === 0) {
      return [];
    }

    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getData(table, params = {}) {
  const query_params = { ...params, table: table };

  try {
    const response = await makeRequest('get', { query_params });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postData(table, data) {
  const body = {};
  body['table'] = table;
  body['data'] = data;

  try {
    const response = await makeRequest('post', { body });
    return response;
  } catch (error) {
    console.log('Error:', console.error);
    throw error;
  }
}

export async function putData(table, data) {
  const body = {};
  body['table'] = table;
  body['data'] = data;

  try {
    const response = await makeRequest('put', { body });
    return response;
  } catch (error) {
    console.log('Error:', console.error);
    throw error;
  }
}

export async function deleteData(table, data) {
  const body = {};
  body['table'] = table;
  body['data'] = data;

  try {
    const response = await makeRequest('delete', { body });
    return response;
  } catch (error) {
    console.log('Error:', console.error);
    throw error;
  }
}

export async function getUsers(params = {}) {
  const query_params = { ...params };

  try {
    const response = await makeUserRequest('get', { query_params });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postErrorData(body) {
  try {
    const response = await makeErrorRequest('post', { body });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function postUserData(body) {
  try {
    const response = await makeUserRequest('post', { body });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function putUserData(body) {
  try {
    const response = await makeUserRequest('put', { body });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function patchUserData(body = {}) {
  try {
    const response = await makeUserRequest('patch', { body });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteUserData(body = {}) {
  try {
    const response = await makeUserRequest('delete', { body });
    return response.data;
  } catch (error) {
    throw error;
  }
}
