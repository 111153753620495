import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { ColorModeContext, tokens } from "../../theme";
import { useLocation, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import MenuItem from "./MenuItem";
import SubItem from "./SubItem";
import { useContext } from "react";
import UserContext from "../../contexts/UserContext";
import Navbar from "../Navbar";
import Header from "../header/header";
import Container from "../container/Container";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function AppLayout({ children }) {
  return (
    <>
      <Header />
      <Navbar />
      <Container sx={{ width: "100%", overflowX: "hidden", pt: "150px" }}>
        <Box component="main" sx={{ width: "100%" }}>
          {children}
        </Box>
      </Container>
    </>
  );
}
