// src/AppRoutes.js
import React, { useContext } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";

import EpisodeDashboard from "./pages/patientData/episodeDashboard";
import EpisodeTMSTreatmentPlans from "./pages/patientData/episodeTMSTreatmentPlans";
import EpisodeTestScores from "./pages/patientData/episodeTestScores";
import EpisodeMedications from "./pages/patientData/episodeMedications";
import EpisodeDiagnosis from "./pages/patientData/episodeDiagnosis";

import PracticePatientsGrid from "./pages/master_data/practice_patients";
import PracticeOfficesGrid from "./pages/master_data/practice_offices";
import PracticePractitionersGrid from "./pages/master_data/practice_practitioners";
import PracticeTMSTreatmentPlanGrid from "./pages/master_data/practice_tms_treatment_plans";
import PracticeTMSProtocolsGrid from "./pages/master_data/practice_tms_protocols";
import PracticeTMSCoilsGrid from "./pages/master_data/practice_tms_coils";
import PracticeTMSDevicesGrid from "./pages/master_data/practice_tms_devices";
import DiagnosisCodesGrid from "./pages/reference_data/diagnosis_codes";
import DisordersGrid from "./pages/reference_data/disorders";
import ProcedureCategoriesGrid from "./pages/reference_data/procedure_categories";
import ProcedureCodesGrid from "./pages/reference_data/procedure_codes";
import TMSDevicesGrid from "./pages/reference_data/tms_devices";
import TMSCoilsGrid from "./pages/reference_data/tms_coils";
import TMSFrequenciesGrid from "./pages/reference_data/tms_frequencies";
import TMSPulseTypesGrid from "./pages/reference_data/tms_pulse_types";
import TMSStimulationSitesGrid from "./pages/reference_data/tms_stimulation_sites";
import TMSProtocolGrid from "./pages/reference_data/tms_protocols";
import BiomarkerTypesGrid from "./pages/reference_data/biomarker_types";
import BiomarkersGrid from "./pages/reference_data/biomarkers";
import Login from "./pages/Login";
import { RequireAuth } from "./utils/RequireAuth";
import OutcomesAnalysis from "./pages/dashboards/outcomesAnalysis";
import FollowUpsAnalysis from "./pages/dashboards/followUpsAnalysis";
import PatientRetentionAnalysis from "./pages/dashboards/patientRetentionAnalysis";
import PracticeOfficeTreatmentsGrid from "./pages/master_data/practice_office_treatments";
import MenuItemsGrid from "./pages/reference_data/menu_items";
import UserContext from "./contexts/UserContext";
import VideoPlayer from "./components/videoPlayer/VideoPlayer";
import EpisodesSecurity from "./components/episodesSecurity/EpisodesSecurity";
import PracticeTreatmentStatusGrid from "./pages/master_data/practice_treatment_status";
import MenuComponentGrid from "./pages/reference_data/menu_components";
import PracticeTreatmentStatusReasonGrid from "./pages/master_data/practice_treatment_status_reason";
import SecurityGroupMenus from "./pages/reference_data/security_groups_menus";
import PracticeTreatmentProgramGrid from "./pages/master_data/practice_treatment_programs";
import TreatmentProgramGrid from "./pages/reference_data/treatment_programs";
import TreatmentTypeGrid from "./pages/reference_data/treatment_types";
import TreatmentStatusGrid from "./pages/reference_data/treatment_status";
import TreatmentStatusReasonGrid from "./pages/reference_data/treatment_status_reason";
import PracticeUserGrid from "./pages/master_data/practice_users";
import MenuGrid from "./pages/reference_data/menus";
import SecurityGroupGrid from "./pages/reference_data/security-groups";
import PracticeGrid from "./pages/reference_data/practices";
import PracticeTMSEpisodesDataGrid from "./pages/master_data/practice_tms_episodes_of_care";

export const componentMap = {
  PracticeEpisodesDataGrid: PracticeTMSEpisodesDataGrid,
  PracticePatientsGrid: PracticePatientsGrid,
  PracticeOfficesGrid: PracticeOfficesGrid,
  PracticePractitionersGrid: PracticePractitionersGrid,
  PracticeTMSTreatmentPlanGrid: PracticeTMSTreatmentPlanGrid,
  PracticeTMSProtocolsGrid: PracticeTMSProtocolsGrid,
  PracticeTMSCoilsGrid: PracticeTMSCoilsGrid,
  PracticeTMSDevicesGrid: PracticeTMSDevicesGrid,
  PracticeOfficeTreatmentsGrid: PracticeOfficeTreatmentsGrid,
  DiagnosisCodesGrid: DiagnosisCodesGrid,
  DisordersGrid: DisordersGrid,
  ProcedureCategoriesGrid: ProcedureCategoriesGrid,
  ProcedureCodesGrid: ProcedureCodesGrid,
  TMSDevicesGrid: TMSDevicesGrid,
  TMSCoilsGrid: TMSCoilsGrid,
  TMSFrequenciesGrid: TMSFrequenciesGrid,
  TMSPulseTypesGrid: TMSPulseTypesGrid,
  TMSProtocolGrid: TMSProtocolGrid,
  BiomarkerTypesGrid: BiomarkerTypesGrid,
  BiomarkersGrid: BiomarkersGrid,
  OutcomesAnalysis: OutcomesAnalysis,
  FollowUpsAnalysis: FollowUpsAnalysis,
  PatientRetentionAnalysis: PatientRetentionAnalysis,
  TMSStimulationSitesGrid: TMSStimulationSitesGrid,
  VideoPlayer: VideoPlayer,
  PracticeTreatmentStatusGrid: PracticeTreatmentStatusGrid,
  PracticeTreatmentStatusReasonGrid: PracticeTreatmentStatusReasonGrid,
  PracticeTreatmentProgramGrid: PracticeTreatmentProgramGrid,
  TreatmentProgramGrid: TreatmentProgramGrid,
  TreatmentTypeGrid: TreatmentTypeGrid,
  TreatmentStatusGrid: TreatmentStatusGrid,
  TreatmentStatusReasonGrid: TreatmentStatusReasonGrid,
  PracticeUserGrid: PracticeUserGrid,
  MenuGrid: MenuGrid,
  MenuItemsGrid: MenuItemsGrid,
  MenuComponentGrid: MenuComponentGrid,
  SecurityGroupGrid: SecurityGroupGrid,
  SecurityGroupMenus: SecurityGroupMenus,
  PracticeGrid: PracticeGrid,
};

function AppRoutes({ isAuthenticated, handleSuccessfulLogin }) {
  const { menuItems } = useContext(UserContext);

  return (
    <Routes>
      <Route path="/" element={<></>} />
      <Route
        path="/login"
        element={
          isAuthenticated ? (
            <Navigate to="/" />
          ) : (
            <Login onSuccessfulLogin={handleSuccessfulLogin} />
          )
        }
      />
      {menuItems.map((menuItem) => {
        const Component = componentMap[menuItem.menu_component];
        if (!Component) return null;
        return (
          <Route
            key={menuItem.menu_route}
            path={`${menuItem.menu_route}`}
            element={
              <RequireAuth>
                <Component />
              </RequireAuth>
            }
          />
        );
      })}

      <Route
        path={"/"}
        element={
          <RequireAuth>
            <Outlet />
          </RequireAuth>
        }
      >
        <Route
          path="/patient/episode/"
          element={
            <EpisodesSecurity>
              <Outlet />
            </EpisodesSecurity>
          }
        >
          <Route path="dashboard" element={<EpisodeDashboard />} />
          <Route
            path="tms_treatment_plans"
            element={<EpisodeTMSTreatmentPlans />}
          />
          <Route path="test_scores" element={<EpisodeTestScores />} />
          <Route path="diagnosis" element={<EpisodeDiagnosis />} />
          <Route path="medications" element={<EpisodeMedications />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default AppRoutes;
