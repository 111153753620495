import * as React from "react";
import { FormControlLabel, Stack, Switch, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import {
  GridRowModes,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import GridExcelExportMenuItem from "../GridExcelExportMenuItem";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

function DefaultToolbar(props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const {
    rows,
    setRows,
    setRowModesModel,
    columns,
    onInactiveToggle,
    hideAddIcon,
    showInactive,
    onClick,
  } = props;

  const handleClick = () => {
    if (props.type && props.type === "form_entry") {
      onClick();
      return;
    }
    const newData = onClick(rows);
    newData.isNew = true;
    if (!newData.hasOwnProperty("id")) {
      newData.newId = Math.max(...rows.map((r) => r.id * 1)) + 1;
    }
    setRows((oldRows) => [...oldRows, newData]);
    setRowModesModel((oldModel) => {
      const firstEditable = columns.find((c) => c.editable && !c.hide);
      return {
        ...oldModel,
        [newData.id]: {
          mode: GridRowModes.Edit,
          fieldToFocus: firstEditable.field,
        },
      };
    });
  };

  if (hideAddIcon === false) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExportContainer>
          <GridExcelExportMenuItem columns={columns} />
          <GridCsvExportMenuItem />
        </GridToolbarExportContainer>
        <GridToolbarQuickFilter />
        <Button
          color="primary"
          startIcon={<AddIcon style={{ color: colors.iconColor[100] }} />}
          onClick={handleClick}
        >
          Add record
        </Button>
        {onInactiveToggle && (
          <Stack sx={{ flex: 1, alignItems: `flex-end` }}>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  title="Show Inactive"
                  value={showInactive}
                  onChange={onInactiveToggle}
                />
              }
              label="Show Inactive"
            />
          </Stack>
        )}
      </GridToolbarContainer>
    );
  } else {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExportContainer>
          <GridExcelExportMenuItem columns={columns} />
          <GridCsvExportMenuItem />
        </GridToolbarExportContainer>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }
}

DefaultToolbar.defaultProps = {
  createRowData: (rows) => {
    const newId = Math.max(...rows.map((r) => r.id * 1)) + 1;
    return { id: newId };
  },
  hideAddIcon: false,
};

export default DefaultToolbar;
