import React, { useState } from "react";
import {
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Menu,
} from "@mui/material";
import useColors from "../../hooks/use-colors";

const MenuItem = ({ prefix, children, active }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const menuOpen = Boolean(anchorEl);
  const handleItemClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const { colors } = useColors();

  // styling
  let color = colors.menuText;

  if (menuOpen || active) {
    color = colors.menuActive;
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  return (
    <>
      <ListItem
        disablePadding
        sx={{
          display: "block",
          width: "fit-content",
          marginLeft: "30px",
          paddingBlock: "10px",
        }}
        onClick={handleItemClick}
      >
        <ListItemButton
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            padding: "0 30px 0 0",
            minHeight: "auto",
            borderRight: "1px solid #515365",
            "&.MuiButtonBase-root:hover": {
              background: "inherit",

              "& .MuiTypography-root": {
                color: colors.menuActive,
              },
            },
            "& .MuiTypography-root": {
              color,
            },
          }}
          disableRipple
          onClick={handleClick}
        >
          {/* <ListItemIcon
          sx={{
            minWidth: 0,
            justifyContent: "center",
            "& svg": {
              width: "1.2rem",
              height: "1.2rem",
            },
          }}
        >
          <FontAwesomeIcon color={color} icon={'fa'} />
        </ListItemIcon> */}

          <ListItemText
            primary={prefix}
            primaryTypographyProps={{ variant: "body1" }}
          />
        </ListItemButton>
      </ListItem>

      <Menu
        disableScrollLock
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          "& .MuiList-root": {
            p: 0,
            width: "200px",
          },
          "& .MuiPaper-root": {
            background: colors.menuBackground,
            marginTop: "5px",
          },
        }}
      >
        {children}
      </Menu>
    </>
  );
};

export default MenuItem;
