import { Box, IconButton } from "@mui/material";
import React from "react";
import Container from "../container/Container";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import useColors from "../../hooks/use-colors";

const Header = () => {
  const { colors, colorMode, isDark } = useColors();
  const navigate = useNavigate();

  async function signOut() {
    try {
      navigate("/");
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      throw error;
    }
  }

  return (
    <Box
      component={"header"}
      sx={{
        padding: "11px 0 11px 0",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 100,
        background: colors.background,
        height: "72px",
      }}
    >
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <img alt="logo" src="/logo.png" style={{ width: "150px" }} />

        <Box display="flex" justifyContent={`flex-end`}>
          <IconButton
            onClick={colorMode.toggleColorMode}
            sx={{ color: colors.foreground }}
          >
            {isDark ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
          </IconButton>

          <IconButton onClick={signOut} sx={{ color: colors.foreground }}>
            <PowerSettingsNewIcon />
          </IconButton>
        </Box>
      </Container>
    </Box>
  );
};

export default Header;
