import React, { useContext } from "react";
import Container from "./container/Container";
import useColors from "../hooks/use-colors";
import { Box, List, Stack, Typography } from "@mui/material";
import UserContext from "../contexts/UserContext";
import { useLocation } from "react-router-dom";
import MenuItem from "./nav/MenuItem";
import SubMenuItem from "./nav/SubMenuItem";

const Navbar = () => {
  const { colors } = useColors();
  const { menus, menuItems } = useContext(UserContext);
  const updatedMenuItems = [...menuItems];
  const { pathname } = useLocation();

  const convertBackendMenuToTopMenu = (menus) => {
    const menuMap = {};
    menus.forEach((menu) => {
      menuMap[menu.name] = { ...menu, items: [] };
      const _menuItems = updatedMenuItems.filter(
        (item) => item.menu === menu.name
      );

      _menuItems.forEach((item) => {
        const section = item.menu_section;
        if (section) {
          let categoryGroup = menuMap[menu.name].items.find(
            (group) => group.section === section
          );

          if (!categoryGroup) {
            categoryGroup = {
              section,
              subItems: [],
            };
            menuMap[menu.name].items.push(categoryGroup);
          }

          categoryGroup.subItems.push(item);
        } else {
          menuMap[menu.name].items.push(item);
        }
      });
    });
    return Object.values(menuMap);
  };

  const topMenu = convertBackendMenuToTopMenu(menus);

  const isMenuActive = (items) => {
    const _items = [];
    for (const item of items) {
      if (item.subItems) {
        _items.push(...item.subItems);
      } else {
        _items.push(item);
      }
    }
    const activeItem = _items.find((sub) => sub.menu_route === pathname);
    return Boolean(activeItem);
  };

  return (
    <Box
      component={"nav"}
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 100,
        background: colors.background,
        top: "72px",
      }}
    >
      <Container>
        <Stack
          direction={"row"}
          sx={{ background: colors.menuBackground, borderRadius: "5px", width: "100%" }}
        >
          <List
            sx={{
              p: 0,
              overflow: "auto",
              display: "flex",
              justifyContent: "flex-start",
              flex: 1,
            }}
            className={`sidebar-list`}
          >
            {topMenu.map((menu, index) => (
              <MenuItem
                key={`menu-item-${index}`}
                prefix={menu.name}
                icon={menu.icon}
                iconType={menu.iconType}
                active={isMenuActive(menu.items)}
              >
                {menu.items.map((item, subIndex) =>
                  item.section ? (
                    <div key={`sub-${subIndex}`}>
                      <Typography
                        variant="subtitle1"
                        whiteSpace={"pre"}
                        fontWeight={700}
                        sx={{
                          m: "15px 0 0 1rem",
                          textTransform: "uppercase",
                          textAlign: "left",
                        }}
                        color={colors.menu.text}
                      >
                        {item.section}
                      </Typography>
                      {item.subItems.map((subItem, subSubIndex) => (
                        <SubMenuItem
                          active={pathname === subItem.menu_route}
                          key={`subitem-${subIndex}-${subSubIndex}`}
                          item={subItem}
                        />
                      ))}
                    </div>
                  ) : (
                    <SubMenuItem
                      hideIcon
                      active={pathname === item.menu_route}
                      key={`subitem-${subIndex}`}
                      item={item}
                    />
                  )
                )}
              </MenuItem>
            ))}
          </List>
        </Stack>
      </Container>
    </Box>
  );
};

export default Navbar;
