import React, { useEffect, useState, useContext } from "react";
import DataEntryForm from "../../../components/datagrid/dataEntryForm";
import UserContext from "../../../contexts/UserContext";
import { getData, postData, putData, deleteData } from "../../../utils/API";
import ShowAlert from "../../../utils/ShowAlert";
import { useNotificationHandling } from "../../../utils/NotificationHandling";
import { useLocation } from "react-router-dom";
import useSecurity from "../../../hooks/use-security";
import PracticeTMSDeviceForm from "./PracticeTMSDeviceForm";

const PracticeTMSDevicesGrid = () => {
  const {
    menuItems,
    practiceId,
    securityGroupMenus,
    practice_name,
    practice_id,
  } = useContext(UserContext);
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();
  const { pathname } = useLocation();
  const { canCreate, canUpdate, canDelete } = useSecurity({
    menuItems,
    pathname,
    securityGroupMenus,
  });

  const title = `${practice_name} - TMS Devices`;
  const table = "practice_tms_devices";
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [tmsDevices, setTmsDevices] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const isUpdate = Boolean(selectedRow);

  const columns = [
    { field: "device_name", headerName: "Device Name", flex: 1 },
    { field: "device_manufacturer", headerName: "Manufacturer", flex: 1 },
    { field: "device_model_number", headerName: "Model", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      type: "singleSelect",
      flex: 1,
      valueOptions: ["Active", "Inactive"],
    },
  ];

  const getRows = async () => {
    try {
      const practiceDevices = await getData(table, {
        practice_id,
        deleted: false,
      });
      setRows(practiceDevices);
    } catch (error) {
      throw error;
    }
  };

  const getTmsDevices = async () => {
    try {
      const allTmsDevices = await getData("tms_devices", {});
      setTmsDevices(allTmsDevices);
    } catch (error) {
      throw error;
    }
  };

  const handleOpenModal = (id) => {
    if (id) {
      const row = rows.find((row) => row.id === id);
      setSelectedRow(row);
      getEpisodeTreatmentPlans(
        row.device_manufacturer,
        row.device_model_number
      );
    }
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);

    if (selectedRow) {
      setSelectedRow(null);
    }
  };

  const handleOnSubmit = async (values) => {
    setLoading(true);
    handleCloseModal();
    try {
      const payload = { tms_device_id: values.tms_device_id, practice_id };
      if (!isUpdate) {
        await postData(table, payload);
        await getRows();
      } else {
        await putData(table, { ...payload, id: selectedRow.id });
        await getRows();
      }
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRow = async (id) => {
    setLoading(true);
    try {
      await deleteData(table, { id });
      setRows((prevRows) => prevRows.filter((row) => row.id !== id));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      setLoading(false);
    }
  };

  const initFetch = async () => {
    try {
      await Promise.all([getRows(), getTmsDevices()]);
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      setLoading(false);
    }
  };

  const getEpisodeTreatmentPlans = async (
    device_manufacturer,
    device_model_number
  ) => {
    setLoading(true);
    try {
      let practiceTreatmentPlans = await getData(
        "practice_tms_treatment_plans",
        {
          practice_id: practiceId,
          device_manufacturer,
          device_model_number,
        }
      );
      practiceTreatmentPlans = practiceTreatmentPlans.filter(
        (t) =>
          t.device_manufacturer === device_manufacturer &&
          t.device_model_number === device_model_number
      );

      if (practiceTreatmentPlans.length > 0) {
        const customError = new Error();
        customError.name = "Validation Error";
        customError.message =
          "Device is already used in treatment plans. Editing Manufacturer or Model Number is prohibited.";
        throw customError;
      }
    } catch (error) {
      handleErrorNotification(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    initFetch();
  }, []);

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  return (
    <div>
      <DataEntryForm
        title={title}
        columns={columns}
        rows={rows}
        loading={loading}
        uniqueKey="PracticeTMSDevicesGrid"
        onAdd={() => handleOpenModal()}
        onEdit={handleOpenModal}
        onDeleteRow={handleDeleteRow}
        disableAdd={!canCreate}
        disableEdit={!canUpdate}
        disableDelete={!canDelete}
      />

      <PracticeTMSDeviceForm
        open={isOpenModal}
        selectedRow={selectedRow}
        onSubmit={handleOnSubmit}
        onCancel={handleCloseModal}
        tmsDevices={tmsDevices}
        rows={rows}
        loading={loading}
      />
    </div>
  );
};

export default PracticeTMSDevicesGrid;
