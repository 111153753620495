import * as React from "react";
import "../../../index.css";
import { useEffect, useState, useContext } from "react";
import UserContext from "../../../contexts/UserContext";
import { getData, postData, putData } from "../../../utils/API";
import { useNotificationHandling } from "../../../utils/NotificationHandling";
import DataEntryForm from "../../../components/datagrid/dataEntryForm";
import ShowAlert from "../../../utils/ShowAlert";
import PracticeTMSTreatmentPlanForm from "./PracticeTMSTreatmentPlanForm";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import useSecurity from "../../../hooks/use-security";

const table = "practice_tms_treatment_plans";
const subtitle = "TMS Treatment Plans";

export default function PracticeTMSTreatmentPlanGrid() {
  const { practiceId, practice_name, menuItems, securityGroupMenus } =
    useContext(UserContext);
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();
  const title = practice_name;
  const [officeObjects, setOfficeObjects] = useState([]);
  const [protocols, setProtocols] = useState([]);
  const [practiceTmsCoils, setPracticeTmsCoils] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rows, setRawRows] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedRow, setRow] = useState(null);
  const isUpdate = Boolean(selectedRow);
  const { pathname } = useLocation();
  const { canCreate, canUpdate, canDelete } = useSecurity({
    menuItems,
    pathname,
    securityGroupMenus,
  });

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "office_name",
      headerName: "Office",
      cellClassName: "name-column--cell",
      type: "singleSelect",
      valueOptions: officeObjects.map((office) => office.name),
      flex: 1,
    },
    {
      field: "tms_protocol_name",
      headerName: "Protocol",
      cellClassName: "name-column--cell",
      type: "singleSelect",
      valueOptions: protocols.map((protocol) => protocol.name),
      flex: 1,
    },
    {
      field: "device_name",
      type: "singleSelect",
      valueOptions: [
        ...new Set(practiceTmsCoils.map((item) => item.device_name)),
      ],
      headerName: "Device",
      flex: 1,
    },
    {
      field: "coil_name",
      headerName: "Coil",
      headerAlign: "center",
      type: "singleSelect",
      valueOptions: practiceTmsCoils.map((item) => item.coil_model_number),
      align: "center",
      flex: 1,
    },
    {
      field: "total_pulses",
      headerName: "Total Pulses",
      headerAlign: "center",
      align: "center",
      valueFormatter: ({ value }) => value.toLocaleString(),
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      type: "singleSelect",
      valueOptions: ["Active", "Inactive"],
      flex: 1,
    },
  ];

  const setRows = (rows) => {
    if (!Array.isArray(rows)) {
      return;
    }
    setRawRows(rows.map((r, i) => ({ ...r, no: i + 1 })));
  };

  const getRows = async function () {
    setLoading(true);
    try {
      const data = await getData(table, {
        practice_id: practiceId,
        deleted: false,
      });
      setRows(data);
    } catch (error) {
      throw error;
    }
    setLoading(false);
  };

  const getOffices = async function () {
    try {
      const response = await getData("practice_offices", { deleted: false });
      setOfficeObjects(response);
    } catch (err) {
      throw err;
    }
  };

  async function deleteRow(id, row, oldRows) {
    const rowToSave = { ...row, deleted: true };

    try {
      await putData(table, rowToSave);
      setRows(oldRows.filter((r) => r.id !== id));
      return "Deleted";
    } catch (error) {
      throw error;
    }
  }

  const getPracticeTmsCoil = async function () {
    try {
      const data = await getData("practice_tms_coils", { deleted: false });
      setPracticeTmsCoils(data);
    } catch (err) {
      throw err;
    }
  };

  const getProtocols = async function () {
    try {
      const data = await getData("practice_tms_protocols", { deleted: false });
      setProtocols(data);
    } catch (err) {
      throw err;
    }
  };

  const handleOpenModal = (id) => {
    if (
      !officeObjects ||
      officeObjects.length === 0 ||
      !practiceTmsCoils ||
      practiceTmsCoils.length === 0 ||
      !protocols ||
      protocols.length === 0
    ) {
      const customError = new Error();
      customError.name = "Data Error";
      customError.message = `Data could not be retrieved at this time. Please check your connection and try again, or contact support if the issue persists.`;
      handleErrorNotification(customError);
      return; // Prevent opening the modal
    }

    let selectedRow = null;
    setIsOpenModal(true);
    if (id) {
      selectedRow = rows.find((r) => r.id === id);
      setRow(selectedRow);
    }
  };

  const handleModalClose = () => {
    setIsOpenModal(false);
    if (selectedRow) {
      setRow(null);
    }
  };

  const handleOnSubmit = async (values) => {
    const rowToSave = {
      ...values,
      deleted: false,
    };

    handleModalClose();
    try {
      if (!isUpdate) {
        await postData(table, rowToSave);
        await getRows();
      } else {
        await putData(table, { ...rowToSave, id: selectedRow.id });
        await getRows();
      }
    } catch (error) {
      handleErrorNotification(error);
    }
  };

  const initialFetch = async () => {
    try {
      await Promise.all([
        getRows(),
        getOffices(),
        getProtocols(),
        getPracticeTmsCoil(),
      ]);
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initialFetch();
  }, []);

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  return (
    <div>
      <DataEntryForm
        title={title}
        subtitle={subtitle}
        columns={columns}
        rows={rows}
        loading={loading}
        onAdd={handleOpenModal}
        onEdit={handleOpenModal}
        onDeleteRow={deleteRow}
        disableAdd={!canCreate}
        disableEdit={!canUpdate}
        disableDelete={!canDelete}
      />
      <Dialog open={isOpenModal} onClose={() => setIsOpenModal(false)}>
        <DialogTitle>{`${isUpdate ? "Edit" : "Add"} ${subtitle}`}</DialogTitle>
        <DialogContent sx={{ width: "100%", maxWidth: "500px" }}>
          <PracticeTMSTreatmentPlanForm
            onCancel={handleModalClose}
            onSubmit={handleOnSubmit}
            offices={officeObjects}
            practiceTmsCoils={practiceTmsCoils}
            protocols={protocols}
            selectedRow={selectedRow}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
