import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";

const SubMenuItem = ({ active, item, hideIcon }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleClick = (e) => {
    e.preventDefault();
    navigate(item.menu_route);
  };

  // styling
  const background = colors.menuBackground;
  let color = colors.menuText;

  if (active) {
    color = colors.menuActive;
  }

  return (
    <ListItem disablePadding sx={{ display: "block", my: "2.5px" }}>
      <ListItemButton
        sx={{
          justifyContent: "initial",
          px: 1.5,
          borderRadius: 1,
          background,
          "& .MuiListItemText-root": {
            margin: 0,
          },
          "&.MuiButtonBase-root:hover": {
            "& .MuiTypography-root": {
              color: colors.menuActive,
            },
          },
        }}
        onClick={handleClick}
      >
        {/* <ListItemIcon
          color={color}
          sx={{
            minWidth: 0,
            mr: "auto",
            "& .MuiSvgIcon-root": {
              color,
            },
          }}
        >
          {item.iconType === "fontawesome" && item.icon ? (
            <FontAwesomeIcon icon={item.icon} />
          ) : (
            item.icon
          )}
        </ListItemIcon> */}
        <ListItemText
          primary={item.menu_item}
          sx={{
            "& .MuiTypography-root": {
              color,
            },
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default SubMenuItem;
