import * as React from "react";
import { useEffect, useState, useContext } from "react";
import DataEntry from "../../../components/datagrid/dataEntry";
import UserContext from "../../../contexts/UserContext";
import { getData, postData, putData } from "../../../utils/API";
import {
  validatePostalCodeFormat,
  validatePostalCodeExists,
  validateRequiredAttributes,
  validateUniqueProperty,
} from "../../../utils/ValidationUtils";
import ShowAlert from "../../../utils/ShowAlert";
import { useNotificationHandling } from "../../../utils/NotificationHandling";
import { useLocation } from "react-router-dom";
import useSecurity from "../../../hooks/use-security";

export default function PracticeOfficesGrid() {
  const { menuItems, securityGroupMenus, practice_name } =
    useContext(UserContext);
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();
  const { pathname } = useLocation();
  const title = practice_name;
  const subtitle = "Offices";
  const table = "practice_offices";
  const requiredAttributes = ["name", "postal_code"];
  const attributeNames = ["Office name", "Postal Code"];
  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "name",
      headerName: "Name",
      editable: true,
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "postal_code",
      headerName: "Zip Code",
      headerAlign: "center",
      align: "center",
      editable: true,
      flex: 1,
    },
    {
      field: "virtual",
      headerName: "Telehealth",
      editable: true,
      type: "boolean",
      defaultValueGetter: () => false,
    },
    {
      field: "city",
      headerName: "City",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "state",
      headerName: "State",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      editable: true,
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: ["Active", "Inactive"],
      defaultValueGetter: () => "Active",
      flex: 1,
    },
  ];
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const { canCreate, canUpdate, canDelete } = useSecurity({
    menuItems,
    pathname,
    securityGroupMenus,
  });
  const [showInactive, setShowInactive] = useState(false);

  function createRowData(rows) {
    // IS THIS REDUNDANT, ITS ALSO IN DefaultToolBar
    const newId = Math.floor(100000 + Math.random() * 900000);
    return {
      id: newId,
      name: "",
      postal_code: "",
      status: "Active",
      virtual: false,
    };
  }

  async function validateRow(newRow, oldRows) {
    try {
      validateRequiredAttributes(requiredAttributes, attributeNames, newRow);
      validateUniqueProperty(rows, newRow, "name");
      validatePostalCodeFormat(newRow.postal_code);
      const postalCodeInfo = await validatePostalCodeExists(newRow.postal_code);
      const updatedRow = { ...newRow, ...postalCodeInfo };
      return updatedRow;
    } catch (error) {
      throw error;
    }
  }

  async function saveRow(id, row, oldRow, oldRows) {
    try {
      if (row.isNew) {
        const rowToSave = { ...row, deleted: false };
        delete rowToSave.id;
        const data = await postData(table, rowToSave);
        // Add the id returned from the database
        rowToSave.id = data.data.id;
        // Get duplicate key error without this
        rowToSave.isNew = false;
        setRows(oldRows.map((r) => (r.id === id ? { ...rowToSave } : r)));
        return rowToSave;
      } else {
        await putData(table, row);
        setRows(oldRows.map((r) => (r.id === id ? { ...row } : r)));
        return row;
      }
    } catch (error) {
      setRows(oldRows);
      throw error;
    }
  }

  async function episodesOfCareExists(row) {
    try {
      const result = await getData("episodes_of_care", {
        office_id: row.id,
        deleted: false,
      });
      if (result.length === 0) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      return true;
    }
  }

  async function deleteRow(id, row, oldRows) {
    // // Need to create an error object for this condition
    // const episodeExists = await episodesOfCareExists(row);
    // if (episodeExists) {
    //   const customError = new Error();
    //   customError.name = "Delete Error";
    //   customError.message = `The ${row.name} office has treated patients and cannot be deleted.\nSet the status to Inactive to hide the Office.`;
    //   handleErrorNotification(customError);
    //   return;
    // }
    // const rowToSave = { ...row, deleted: true };
    // try {
    //   await putData(table, rowToSave);
    //   setRows(oldRows.filter((r) => r.id !== id));
    //   return "Deleted";
    // } catch (error) {
    //   setRows(oldRows);
    //   throw error;
    // }
  }

  const getRows = async () => {
    setLoading(true);
    try {
      const queryParam = {
        deleted: false,
      };

      const data = await getData(table, queryParam);
      setAllRows(data);
      setLoading(false);
    } catch (error) {
      throw error;
    }
    setLoading(false);
  };

  const handleInactiveToggle = (e) => {
    const checked = e.target.checked;
    setShowInactive(checked);
  };

  const initialFetch = async () => {
    try {
      await Promise.all([getRows()]);
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initialFetch();
  }, []);

  useEffect(() => {
    if (showInactive) {
      setRows(allRows);
    } else {
      setRows(allRows.filter((row) => row.status === "Active"));
    }
  }, [allRows, showInactive]);

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  return (
    <div>
      <DataEntry
        title={title}
        subtitle={subtitle}
        columns={columns}
        rows={rows}
        onValidateRow={validateRow}
        onSaveRow={saveRow}
        onDeleteRow={deleteRow}
        createRowData={createRowData}
        loading={loading}
        hideAddIcon={!canCreate}
        disableEdit={!canUpdate}
        disableDelete={!canDelete}
        showInactive={showInactive}
        onInactiveToggle={handleInactiveToggle}
      />
    </div>
  );
}
