import { useState, useCallback } from 'react';
import { postErrorData } from './API';
import { isProdMode } from './Config';

export function useNotificationHandling() {
  const [notificationState, setNotificationState] = useState({
    severity: '',
    title: '',
    message: '',
    description: '',
    showNotification: false,
  });

  // Function to handle Axios-related errors
  const handleAxiosError = async (error) => {
    const title = 'Network Error';
    const name = error?.code || '';
    const code = error?.request?.status || '';
    let message = error?.message || 'Network error occurred.';
    let description = error?.stack || '';

    try {
      await postErrorData({
        source: 'Axios',
        name: name,
        message: message,
        code: code,
        description: description,
      });
    } catch (error) {
      console.error('Error logging error data:', error);
    }

    if (isProdMode) {
      message =
        'Please check your internet connection and try again. If the issue persists, contact support.';
      description = '';
    } else {
      message = `${code}: ${name}: ${message}`;
    }

    return { title, message, description };
  };

  // Function to handle Database-related errors
  const handleDatabaseError = async (error) => {
    const title = 'Database Error';
    const name =
      error?.response?.data?.name || error?.name || 'Unknown Database Error'; // Added a more generic fallback name

    let code = error?.response?.data?.code || error?.code || '';

    let message =
      error?.response?.data?.message ||
      error?.message ||
      'Unknown database error occurred.';

    let description = error?.response?.data?.description || '';

    try {
      await postErrorData({
        source: 'Database',
        name: name,
        message: message,
        code: code,
        description: description,
      });
    } catch (error) {
      console.error('Error logging error data:', error);
    }

    // If in production mode, use a user-friendly message
    if (isProdMode) {
      message =
        "We're unable to process your request right now. Please try again later. If the issue persists, contact support.";
      description = '';
    } else {
      message = `${name}: ${message}`;
    }

    return { title, message, description }; // Include name in the return object
  };

  // Function to handle general or custom errors
  const handleGeneralError = (error) => {
    const title = error?.name || 'UnknownErrorName';
    const message = error?.message || '';
    const description = error?.description || '';

    return { title, message, description };
  };

  // Dispatcher function to handle errors based on type
  const handleErrorByType = async (error, source) => {
    if (source === 'Axios') {
      return await handleAxiosError(error);
    } else if (source === 'Database') {
      return await handleDatabaseError(error);
    } else {
      return handleGeneralError(error);
    }
  };

  const handleSuccessNotification = useCallback(
    (title, message, description) => {
      let severity = 'success';

      setNotificationState({
        severity,
        title: title || 'Success',
        message: message || '',
        description: description || '',
        showNotification: true,
      });
    },
    []
  );

  const handleInfoNotification = useCallback((title, message, description) => {
    let severity = 'info';

    setNotificationState({
      severity,
      title: title || 'Information',
      message: message || '',
      description: description || '',
      showNotification: true,
    });
  }, []);

  const handleErrorNotification = useCallback(
    async (error) => {
      const severity = 'error';
      let source = 'Unknown';

      // Determine the error source
      if (error.code === 'ERR_BAD_REQUEST' && error.response?.data?.name) {
        source = 'Database';
      } else if (error.name === 'AxiosError') {
        source = 'Axios';
      }

      console.log('isProdMode:', isProdMode);
      console.log('error source:', source);
      console.log('error:', error);

      // Get error details based on the type
      const { title, message, description } = await handleErrorByType(
        error,
        source
      );

      console.log(
        'display notification',
        severity,
        title,
        message,
        description
      );

      // Set notification state
      setNotificationState({
        severity,
        title,
        message,
        description,
        showNotification: true,
      });
    },
    [isProdMode, setNotificationState]
  );

  const handleClose = useCallback(() => {
    setNotificationState({
      severity: '',
      title: '',
      message: '',
      description: '',
      showNotification: false,
    });
  }, []);

  return {
    notificationState,
    handleErrorNotification,
    handleSuccessNotification,
    handleInfoNotification,
    handleClose,
  };
}
