import { Box } from "@mui/material";
import React from "react";

const Container = ({ children, sx = {} }) => {
  return (
    <Box
      sx={{
        display: "flex",
        maxWidth: "1920px",
        margin: "0 auto",
        width: "100%",
        paddingInline: "10px",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default Container;
