import * as React from "react";
import { useEffect, useState, useContext } from "react";
import DataEntry from "../../../components/datagrid/dataEntry";
import UserContext from "../../../contexts/UserContext";
import { getData, postData, putData } from "../../../utils/API";
import {
  validatePostalCodeFormat,
  validatePostalCodeExists,
  validateRequiredAttributes,
  validateBirthDate,
  validateEmailFormat,
  validateUniqueEmail,
  validateUniquePatient,
  typeOfDate,
} from "../../../utils/ValidationUtils";
import ShowAlert from "../../../utils/ShowAlert";
import { useNotificationHandling } from "../../../utils/NotificationHandling";
import { useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import useSecurity from "../../../hooks/use-security";

// *************** CUSTOMIZE ************** START

export default function PracticePatientsGrid() {
  const { menuItems, securityGroupMenus, practiceId, practice_name } =
    useContext(UserContext);
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();

  const title = practice_name;
  const subtitle = "Patients";
  const table = "practice_patients";
  const sort_1 = "last_name";
  const sort_2 = "first_name";
  const requiredAttributes = [
    "last_name",
    "first_name",
    "birthdate",
    "postal_code",
    "gender_birth",
  ];
  const attributeNames = [
    "Last Name",
    "First Name",
    "Birth Date",
    "Postal Code",
    "Birth Gender",
  ];
  const { pathname } = useLocation();

  const columns = [
    {
      field: "last_name",
      headerName: "Last",
      editable: true,
      cellClassName: "name-column--cell",
      flex: 0.5,
    },
    {
      field: "first_name",
      headerName: "First",
      editable: true,
      cellClassName: "name-column--cell",
      flex: 0.5,
    },
    {
      field: "chart_id",
      headerName: "Chart ID",
      editable: true,
      flex: 0.5,
    },
    {
      field: "birthdate",
      type: "date",
      headerName: "Birth Date",
      flex: 0.5,
      editable: true,
      valueGetter: (params) => {
        // On the first render start_date is a string, on subsequent renders its a date object
        const birthDate = params.row.birthdate;
        const birthDateObject = typeOfDate(birthDate, "object");
        return birthDateObject;
      },
    },
    {
      field: "gender_birth",
      headerName: "Birth Gender",
      editable: true,
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: ["M", "F", ""],
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "center",
      align: "center",
      editable: true,
      flex: 1,
    },
    {
      field: "postal_code",
      headerName: "Zip Code",
      headerAlign: "center",
      align: "center",
      editable: true,
      flex: 0.5,
    },
    {
      field: "status",
      headerName: "Status",
      editable: true,
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: ["Active", "Inactive"],
      flex: 0.5,
    },
  ];

  const { canCreate, canUpdate, canDelete } = useSecurity({
    menuItems,
    pathname,
    securityGroupMenus,
  });

  const createRowData = (rows) => {
    // IS THIS REDUNDANT, ITS ALSO IN DefaultToolBar
    const newId = Math.floor(100000 + Math.random() * 900000);
    return {
      id: newId,
      practice_id: practiceId,
      last_name: "",
      first_name: "",
      chart_id: "",
      email: "",
      birthdate: "",
      gender_birth: "",
      postal_code: "",
      status: "Active",
      deleted: false,
    };
  };
  // *************** CUSTOMIZE ************** END

  const [loading, setLoading] = useState(true);
  const [rows, setRawRows] = useState([]);

  const setRows = (rows) => {
    if (!Array.isArray(rows)) {
      return;
    }
    setRawRows(rows.map((r, i) => ({ ...r, no: i + 1 })));
  };

  function sortItems(items, sort_attribute_1, sort_attribute_2) {
    return items.sort((a, b) => {
      const comparison_1 = a[sort_attribute_1].localeCompare(
        b[sort_attribute_1]
      );

      if (comparison_1 === 0 && sort_attribute_2) {
        return a[sort_attribute_2].localeCompare(b[sort_attribute_2]); // Secondary criterion
      }

      return comparison_1;
    });
  }

  async function validateRow(newRow, oldRow) {
    try {
      validateRequiredAttributes(requiredAttributes, attributeNames, newRow);
      if (newRow.email.length > 0) {
        validateEmailFormat(newRow.email);
      }

      validateBirthDate(newRow.birthdate);
      validateUniquePatient(rows, newRow);
      validateUniqueEmail(rows, newRow);
      validatePostalCodeFormat(newRow.postal_code);
      const postalCodeInfo = await validatePostalCodeExists(newRow.postal_code);
      const updatedRow = { ...newRow, ...postalCodeInfo };

      return updatedRow;
    } catch (error) {
      throw error;
    }
  }

  async function saveRow(id, row, oldRow, oldRows) {
    try {
      const rowToSave = { ...row, practice_id: practiceId };
      // Convert the birthdate to the desired format
      const birthDateString = row.birthdate.toISOString().slice(0, 10);
      rowToSave.birthdate = birthDateString;

      if (
        "date_first_appointment" in rowToSave &&
        rowToSave.end_date instanceof Date
      ) {
        const firstApptDateString = row.date_first_appointment
          .toISOString()
          .slice(0, 10);
        rowToSave.date_first_appointment = firstApptDateString;
      } else {
        delete rowToSave.date_first_appointment;
      }

      if (rowToSave.isNew) {
        rowToSave.deleted = false;
        rowToSave.practice_id = practiceId;
        delete rowToSave.id;
        rowToSave["active"] = "Active";
        const data = await postData(table, rowToSave);
        // Add the id returned from the database
        rowToSave.id = data.data.id;
        // Get duplicate key error without this
        rowToSave.isNew = false;
        setRows(oldRows.map((r) => (r.id === id ? { ...rowToSave } : r)));
        rowToSave.birthdate = row.birthdate;
        return rowToSave;
      } else {
        await putData(table, rowToSave);
        rowToSave.birthdate = row.birthdate;
        return rowToSave;
      }
    } catch (error) {
      setRows(oldRows);
      throw error;
    }
  }

  async function episodesOfCareExists(row) {
    try {
      const result = await getData("episodes_of_care", {
        practice_id: practiceId,
        patient_id: row.id,
        deleted: false,
      });
      if (result.length === 0) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      return true;
    }
  }

  async function deleteRow(id, row, oldRows) {
    const episodeExists = await episodesOfCareExists(row);
    if (episodeExists) {
      let fullName = `${row.first_name} ${row.last_name}`;
      const customError = new Error();
      customError.name = "Delete Error";
      customError.message = `${fullName} has an episode of care and cannot be deleted.\nSet the status to Inactive to hide the patient.`;
      handleErrorNotification(customError);
      return;
    }

    let rowToSave = { ...row, deleted: true };
    delete rowToSave.birthdate;
    delete rowToSave.date_first_appointment;

    try {
      await putData(table, rowToSave);
      setRows(oldRows.filter((r) => r.id !== id));
      return "Deleted";
    } catch (error) {
      setRows(oldRows);
      throw error;
    }
  }

  const getRows = async () => {
    setLoading(true);
    try {
      const queryParam = {
        deleted: false,
      };

      const data = await getData(table, queryParam);
      const sortedItems = sortItems(data, sort_1, sort_2);
      setRows(sortedItems);
    } catch (error) {
      throw error;
    }
    setLoading(false);
  };

  const initialFetch = async () => {
    try {
      await Promise.all([getRows()]);
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initialFetch();
  }, [practiceId]);

  if (notificationState.showError) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  return (
    <div>
      <DataEntry
        title={title}
        subtitle={subtitle}
        columns={columns}
        rows={rows}
        onValidateRow={validateRow}
        onSaveRow={saveRow}
        onDeleteRow={deleteRow}
        createRowData={createRowData}
        loading={loading}
        hideAddIcon={!canCreate}
        disableEdit={!canUpdate}
        disableDelete={!canDelete}
      />
    </div>
  );
}
