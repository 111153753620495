import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Auth } from "aws-amplify";
import UserContext from "../contexts/UserContext";
import logger from "../utils/logger";

function Login({ onSuccessfulLogin }) {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const { setUserData, setLoading } = useContext(UserContext);
  const [loadingUserData, setLoadingUserData] = useState(false);
  const from = location.state?.from?.pathname || "/";
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoadingUserData(true);
    setLoading(true)
    try {
      await setUserData();
      setLoadingUserData(false);
      onSuccessfulLogin();
      logger.debug("Info: handle login success");
      navigate("/");
    } catch (error) {}
  };

  const checkAndHandleLogin = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        await handleLogin();
      }
    } catch (error) {}
  };

  // Use the shouldExecuteEffect flag to control the useEffect
  useEffect(() => {
    checkAndHandleLogin();
    if (route === "authenticated" && !loadingUserData) {
      navigate(from, { replace: true });
    }
  }, [route, from, loadingUserData]);

  return (
    <Authenticator
      loginMechanisms={["email"]}
      hideSignUp={true}
      signUpAttributes={["family_name", "given_name", "email"]}
      onAuthEvent={async (event) => {
        if (event.type === "signIn") {
          handleLogin();
        }
      }}
    />
  );
}

export default Login;
