import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Button,
  MenuItem,
  Select,
  FormControl,
  Grid,
  Typography,
  Box,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";

const PracticeTMSCoilsForm = ({
  onCancel,
  onSubmit,
  selectedRow,
  tmsDevices,
  tmsCoils,
  rows,
  loading,
  open,
}) => {
  const disabled = loading;
  const validationSchema = Yup.object().shape({
    tms_device_id: Yup.string().required("A device must be selected"),
    tms_coil_id: Yup.string().required("A coil must be selected"),
  });

  const handleOnSubmit = (values) => {
    onSubmit(values);
  };

  const disableTmsCoilItem = (values, coil_id) => {
    if (!values.tms_device_id) return true;

    return rows.some(
      (row) =>
        row.coil_id === coil_id && row.tms_device_id === values.tms_device_id
    );
  };

  const tmsCoilsByDevices = (tms_device_id) => {
    if (!tms_device_id) return [];
    return tmsCoils.filter((t) => t.tms_device_id === tms_device_id);
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{`${selectedRow ? "Edit" : "Add"} TMS Coils`}</DialogTitle>
      <DialogContent
        sx={{ width: "100%", minWidth: "500px", maxWidth: "500px" }}
      >
        <Formik
          initialValues={{
            tms_device_id: selectedRow?.tms_device_id || "",
            tms_coil_id: selectedRow?.coil_id || "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
          }) => {
            return (
              <Form>
                <Box
                  sx={{
                    maxWidth: 600,
                    mx: "auto",
                    p: 3,
                    boxShadow: 3,
                    borderRadius: 2,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="tms_device_id-label">
                          TMS Device
                        </InputLabel>
                        <Select
                          label="TMS Device"
                          labelId="tms_device_id-label"
                          id="tms_device_id"
                          name="tms_device_id"
                          value={values.tms_device_id}
                          error={
                            touched.tms_device_id &&
                            Boolean(errors.tms_device_id)
                          }
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue("tms_device_id", e.target.value);
                            setFieldValue("tms_coil_id", "");
                          }}
                        >
                          {tmsDevices.map((device, index) => (
                            <MenuItem
                              key={`device-${index}`}
                              value={device.device_id}
                            >
                              {device.device_name}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.tms_device_id && errors.tms_device_id && (
                          <Typography
                            color="error"
                            variant="caption"
                            sx={{ mx: "14px" }}
                          >
                            {errors.tms_device_id}
                          </Typography>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="tms_coil_id-label">TMS Coil</InputLabel>
                        <Select
                          label="TMS Coil"
                          labelId="tms_coil_id-label"
                          id="tms_coil_id"
                          name="tms_coil_id"
                          value={values.tms_coil_id}
                          error={Boolean(errors.tms_coil_id)}
                          onChange={handleChange}
                          disabled={!Boolean(values.tms_device_id) || disabled}
                        >
                          {tmsCoilsByDevices(values.tms_device_id).map(
                            (el, index) => (
                              <MenuItem
                                key={`device-${index}`}
                                value={el.id}
                                disabled={disableTmsCoilItem(values, el.id)}
                              >
                                {el.name}
                              </MenuItem>
                            )
                          )}
                        </Select>

                        {touched.tms_coil_id && errors.tms_coil_id && (
                          <Typography
                            color="error"
                            variant="caption"
                            sx={{ mx: "14px" }}
                          >
                            {errors.tms_coil_id}
                          </Typography>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                      <Button
                        type="button"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        onClick={onCancel}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default PracticeTMSCoilsForm;
