import * as React from "react";
import "../../../index.css";
import { useEffect, useState, useContext } from "react";
import UserContext from "../../../contexts/UserContext";
import { getData, postData, putData } from "../../../utils/API";
import { useNotificationHandling } from "../../../utils/NotificationHandling";
import DataEntryForm from "../../../components/datagrid/dataEntryForm";
import ShowAlert from "../../../utils/ShowAlert";
import PracticeOfficeTreatmentForm from "./PracticeOfficeTreatmentForm";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import useSecurity from "../../../hooks/use-security";

const table = "practice_office_treatments";
const subtitle = "Treatment Types";

export default function PracticeOfficeTreatmentsGrid() {
  const { practiceId, practice_name, menuItems, securityGroupMenus } =
    useContext(UserContext);
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();
  const title = practice_name;
  const [officeObjects, setOfficeObjects] = useState([]);
  const [treatmentPrograms, setTreatmentPrograms] = useState([]);
  const [treatmentTypes, setTreatmentTypes] = useState([]);
  const [populations, setPopulations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedRow, setRow] = useState(null);
  const isUpdate = Boolean(selectedRow);
  const { pathname } = useLocation();
  const { canCreate, canUpdate, canDelete } = useSecurity({
    menuItems,
    pathname,
    securityGroupMenus,
  });
  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "office_name",
      headerName: "Office",
      cellClassName: "name-column--cell",
      type: "singleSelect",
      valueOptions: officeObjects.map((office) => office.name),
      flex: 1,
    },
    {
      field: "treatment_type_id",
      headerName: "Treatment Type",
      type: "singleSelect",
      valueOptions: treatmentTypes
        .filter((tType) =>
          rows.find((row) => row.treatment_type_id === tType.id)
        )
        .map((tType) => tType.name),
      flex: 1,
      valueFormatter: (params) => {
        return (
          treatmentTypes.find(
            (treatmentType) => treatmentType.id === params.value
          )?.name || "N/A"
        );
      },
    },
    {
      field: "population",
      headerName: "Population",
      type: "singleSelect",
      valueOptions: populations
        .filter((population) =>
          rows.find((row) => row.population === population.name)
        )
        .map((population) => population.name),
      flex: 1,
    },
    {
      field: "treatment_program",
      headerName: "Program",
      type: "singleSelect",
      valueOptions: treatmentPrograms
        .filter((program) =>
          rows.find((row) => row.treatment_program === program.name)
        )
        .map((program) => program.name),
      flex: 1,
    },
  ];

  const getRows = async function () {
    setLoading(true);
    try {
      const data = await getData(table, {
        practice_id: practiceId,
        deleted: false,
      });
      setRows(data);
    } catch (error) {
      throw error;
    }
    setLoading(false);
  };

  async function deleteRow(id, row, oldRows) {
    const rowToSave = { ...row, deleted: true };

    try {
      await putData(table, rowToSave);
      setRows(oldRows.filter((r) => r.id !== id));
      return "Deleted";
    } catch (error) {
      throw error;
    }
  }

  const getOffices = async function () {
    try {
      const data = await getData("practice_offices", {
        deleted: false,
        practiceId: practiceId,
      });
      setOfficeObjects(data);
    } catch (error) {
      throw error;
    }
  };

  const getPopulation = async function () {
    try {
      const data = await getData("populations", {
        deleted: false,
      });

      setPopulations(data);
    } catch (error) {
      throw error;
    }
  };

  const getPracticeTreatmentPrograms = async function () {
    try {
      const data = await getData("practice_treatment_programs");

      const treatmentTypeMap = new Map();

      data.forEach((item) => {
        treatmentTypeMap.set(item.treatment_type_id, {
          id: item.treatment_type_id,
          name: item.treatment_type,
        });
      });

      setTreatmentTypes(Array.from(treatmentTypeMap.values()));
      setTreatmentPrograms(data);
    } catch (error) {
      throw error;
    }
  };

  const handleOpenModal = (id) => {
    if (
      !officeObjects ||
      officeObjects.length === 0 ||
      !treatmentTypes ||
      treatmentTypes.length === 0 ||
      !populations ||
      populations.length === 0 ||
      !treatmentPrograms ||
      treatmentPrograms.length === 0
    ) {
      const customError = new Error();
      customError.name = "Data Error";
      customError.message = `Data could not be retrieved at this time. Please check your connection and try again, or contact support if the issue persists.`;
      handleErrorNotification(customError);
      return; // Prevent opening the modal
    }

    let selectedRow = null;
    setIsOpenModal(true);
    if (id) {
      selectedRow = rows.find((r) => r.id === id);
      setRow(selectedRow);
    }
  };

  const handleModalClose = () => {
    setIsOpenModal(false);
    if (selectedRow) {
      setRow(null);
    }
  };

  const handleOnSubmit = async (values) => {
    const rowToSave = {
      office_id: values.office_id,
      treatment_type_id: values.treatment_type_id,
      deleted: false,
      ...(values.treatment_program_id && {
        treatment_program_id: values.treatment_program_id,
      }),
      ...(values.population_id && { population_id: values.population_id }),
    };

    handleModalClose();
    try {
      if (!isUpdate) {
        await postData(table, rowToSave);
        await getRows();
      } else {
        await putData(table, { ...rowToSave, id: selectedRow.id });
        await getRows();
      }
    } catch (error) {
      console.log("error", error);
      handleErrorNotification(error);
    }
  };

  const initialFetch = async () => {
    try {
      await Promise.all([
        getRows(),
        getOffices(),
        getPopulation(),
        getPracticeTreatmentPrograms(),
      ]);
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initialFetch();
  }, [practiceId]);

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  return (
    <div>
      <DataEntryForm
        title={title}
        subtitle={subtitle}
        columns={columns}
        rows={rows}
        loading={loading}
        onAdd={handleOpenModal}
        onEdit={handleOpenModal}
        onDeleteRow={deleteRow}
        disableAdd={!canCreate}
        disableEdit={!canUpdate}
        disableDelete={!canDelete}
      />
      <Dialog open={isOpenModal} onClose={() => setIsOpenModal(false)}>
        <DialogTitle>{`${isUpdate ? "Edit" : "Add"} ${subtitle}`}</DialogTitle>
        <DialogContent sx={{ width: "100%", maxWidth: "500px" }}>
          <PracticeOfficeTreatmentForm
            onCancel={handleModalClose}
            onSubmit={handleOnSubmit}
            offices={officeObjects}
            treatmentTypes={treatmentTypes}
            populations={populations}
            programs={treatmentPrograms}
            selectedRow={selectedRow}
            rows={rows}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
