import React, { useContext, useEffect, useState } from "react";
import DataEntryForm from "../../../components/datagrid/dataEntryForm";
import { deleteData, getData, postData, putData } from "../../../utils/API";
import UserContext from "../../../contexts/UserContext";
import { useNotificationHandling } from "../../../utils/NotificationHandling";
import ShowAlert from "../../../utils/ShowAlert";
import SecurityGroupsMenuForm from "./SecurityGroupsMenuForm";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useLocation } from "react-router-dom";
import useSecurity from "../../../hooks/use-security";

const title = "Security Groups Menus";
const table = "security_group_menus";

const SecurityGroupMenus = () => {
  const [loading, setLoading] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedRow, setRow] = useState(null);
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();
  const { updateMenus } = useContext(UserContext);
  const [securityGroups, setSecurityGroups] = useState([]);
  const isUpdate = Boolean(selectedRow);
  const [menus, setMenus] = useState([]);
  const [rows, setRows] = useState([]);
  const { pathname } = useLocation();
  const { menuItems, securityGroupMenus } = useContext(UserContext);
  const { canCreate, canUpdate, canDelete } = useSecurity({
    menuItems,
    pathname,
    securityGroupMenus,
  });

  const columns = [
    {
      field: "menu",
      headerName: "Menu",
      flex: 0.5,
      type: "singleSelect",
    },
    {
      field: "security_group",
      headerName: "Security Group",
      flex: 0.5,
      type: "singleSelect",
      valueOptions: securityGroups
        .filter((security) =>
          rows.find((row) => row.security_group === security.name)
        )
        .map((security) => security.name),
    },
    {
      field: "read_permission",
      headerName: "Read",
      flex: 0.5,
      type: "boolean",
    },
    {
      field: "create_permission",
      headerName: "Create",
      flex: 0.5,
      type: "boolean",
    },
    {
      field: "update_permission",
      headerName: "Update",
      flex: 0.5,
      type: "boolean",
    },
    {
      field: "delete_permission",
      headerName: "Delete",
      flex: 0.5,
      type: "boolean",
    },
    { field: "status", headerName: "Status", flex: 0.5 },
  ];

  async function deleteRow(id) {
    setLoading(true);
    const body = {
      id,
    };

    try {
      await deleteData(table, body);
      await getRows();
      updateMenus();
      setLoading(false);
      return "Deleted";
    } catch (error) {
      throw error;
    }
  }

  const handleOpenModal = (id) => {
    if (
      !menus ||
      menus.length === 0 ||
      !securityGroups ||
      securityGroups.length === 0
    ) {
      const customError = new Error();
      customError.name = "Data Error";
      customError.message = `Data could not be retrieved at this time. Please check your connection and try again, or contact support if the issue persists.`;
      handleErrorNotification(customError);
      return; // Prevent opening the modal
    }

    let selectedRow = null;
    setIsOpenModal(true);
    if (id) {
      selectedRow = rows.find((r) => r.id === id);
      setRow(selectedRow);
    }
  };

  const handleModalClose = () => {
    setIsOpenModal(false);
    if (selectedRow) {
      setRow(null);
    }
  };

  const getSecurityGroups = async function () {
    try {
      const data = await getData("security_groups", { status: "Active" });
      const sortedData = data.sort((a, b) => {
        if (a.name < b.name) return -1;
        return 0;
      });
      setSecurityGroups(sortedData);
    } catch (error) {
      throw error;
    }
  };

  const getRows = async function () {
    try {
      let data = await getData(table);
      data = data.sort((a, b) => {
        if (a.menu < b.menu) return -1;
        return 0;
      });
      setRows(data);
    } catch (error) {
      throw error;
    }
  };

  const getMenus = async function () {
    try {
      const data = await getData("menus", { status: "Active" });
      setMenus(data);
    } catch (error) {
      throw error;
    }
  };

  const handleOnSubmit = async (values) => {
    const rowToSave = { ...values };
    setLoading(true);
    handleModalClose();
    try {
      if (!isUpdate) {
        await postData(table, rowToSave);
        await getRows();
        updateMenus();
      } else {
        await putData(table, { ...rowToSave, id: selectedRow.id });
        await getRows();
        updateMenus();
      }
    } catch (error) {
      console.log("error", error);
      handleErrorNotification(error);
    }
    setLoading(false);
  };

  const initFetch = async () => {
    try {
      await Promise.all([getRows(), getMenus(), getSecurityGroups()]);
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initFetch();
  }, []);

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  return (
    <div>
      <DataEntryForm
        title={title}
        columns={columns}
        rows={rows}
        loading={loading}
        onAdd={handleOpenModal}
        onEdit={handleOpenModal}
        onDeleteRow={deleteRow}
        filterKey={"security-groups"}
        disableAdd={!canCreate}
        disableEdit={!canUpdate}
        disableDelete={!canDelete}
      />
      <Dialog open={isOpenModal} onClose={handleModalClose}>
        <DialogTitle>{`${
          isUpdate ? "Edit" : "Add"
        } Security Group Menu Item`}</DialogTitle>
        <DialogContent sx={{ width: "100%", maxWidth: "500px" }}>
          <SecurityGroupsMenuForm
            rows={rows}
            menus={menus}
            securityGroups={securityGroups}
            onSubmit={handleOnSubmit}
            onClose={handleClose}
            selectedRow={selectedRow}
            onCancel={handleModalClose}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SecurityGroupMenus;
