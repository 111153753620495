import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Button,
  MenuItem,
  Select,
  FormControl,
  Grid,
  Typography,
  Box,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";

const PracticeTMSDeviceForm = ({
  onCancel,
  onSubmit,
  selectedRow,
  tmsDevices,
  rows,
  loading,
  open,
}) => {
  const disabled = loading;
  const validationSchema = Yup.object().shape({
    manufacturer: Yup.string().required("Manufacturer is required"),
    model_number: Yup.string().required("Model Number is required"),
  });

  const handleOnSubmit = (values) => {
    const tmsDevice = tmsDevices.find(
      (t) =>
        t.manufacturer === values.manufacturer &&
        t.model_number === values.model_number
    );

    if (tmsDevice?.id) {
      onSubmit({ ...values, tms_device_id: tmsDevice.id });
    }
  };

  const manufacturers = () => {
    const arr = [];
    tmsDevices.forEach((t) => {
      if (!arr.includes(t.manufacturer)) {
        arr.push(t.manufacturer);
      }
    });
    return arr;
  };

  const modelNumbers = (device_manufacturer) => {
    const arr = [];
    tmsDevices
      .filter((t) => t.manufacturer === device_manufacturer)
      .forEach((t) => {
        if (!arr.includes(t.model_number)) {
          arr.push(t.model_number);
        }
      });
    return arr;
  };

  const disabledModelNumber = (values, modelNumber) => {
    if (!values.manufacturer) return true;
    return rows.some(
      (row) =>
        row.device_manufacturer === values.manufacturer &&
        row.device_model_number === modelNumber
    );
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{`${selectedRow ? "Edit" : "Add"} TMS Device`}</DialogTitle>
      <DialogContent
        sx={{ width: "100%", minWidth: "500px", maxWidth: "500px" }}
      >
        <Formik
          initialValues={{
            manufacturer: selectedRow?.device_manufacturer || "",
            model_number: selectedRow?.device_model_number || "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
          }) => {
            return (
              <Form>
                <Box
                  sx={{
                    maxWidth: 600,
                    mx: "auto",
                    p: 3,
                    boxShadow: 3,
                    borderRadius: 2,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="manufacturer-label">
                          Manufacturer
                        </InputLabel>
                        <Select
                          label="Manufacturer"
                          labelId="manufacturer-label"
                          id="manufacturer"
                          name="manufacturer"
                          value={values.manufacturer}
                          error={
                            touched.manufacturer && Boolean(errors.manufacturer)
                          }
                          disabled={disabled}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue("manufacturer", e.target.value, true);
                            setFieldValue("model_number", "");
                          }}
                        >
                          {manufacturers().map((device, index) => (
                            <MenuItem key={`device-${index}`} value={device}>
                              {device}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.manufacturer && errors.manufacturer && (
                          <Typography
                            color="error"
                            variant="caption"
                            sx={{ mx: "14px" }}
                          >
                            {errors.manufacturer}
                          </Typography>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="model-number-label">
                          Model Number
                        </InputLabel>
                        <Select
                          label="Model Number"
                          labelId="model-number-label"
                          id="model_number"
                          name="model_number"
                          value={values.model_number}
                          error={Boolean(errors.model_number)}
                          onChange={handleChange}
                          disabled={!Boolean(values.manufacturer) || disabled}
                        >
                          {modelNumbers(values.manufacturer).map(
                            (modelNumber, index) => (
                              <MenuItem
                                key={`device-${index}`}
                                value={modelNumber}
                                disabled={disabledModelNumber(
                                  values,
                                  modelNumber
                                )}
                              >
                                {modelNumber}
                              </MenuItem>
                            )
                          )}
                        </Select>

                        {touched.model_number && errors.model_number && (
                          <Typography
                            color="error"
                            variant="caption"
                            sx={{ mx: "14px" }}
                          >
                            {errors.model_number}
                          </Typography>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                      <Button
                        type="button"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        onClick={onCancel}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default PracticeTMSDeviceForm;
