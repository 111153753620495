import { useTheme } from "@mui/material";
import { ColorModeContext, tokens } from "../theme";
import { useContext } from "react";

function useColors() {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const colors = tokens(theme.palette.mode);
  const isDark = theme.palette.mode === "dark";
  return { colorMode, colors, isDark };
}

export default useColors;
