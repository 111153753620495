import React, {
  createContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { Auth } from "aws-amplify";
import { getData } from "../utils/API";
import { sortMenuItems } from "../utils/menu";
import { useNotificationHandling } from "../utils/NotificationHandling";
import ShowAlert from "../utils/ShowAlert";

const staticDefaultUserValue = {
  practiceId: "",
  email: "",
  firstName: "",
  lastName: "",
  securityGroupMenus: [],
};

export const UserContext = createContext(staticDefaultUserValue);

function UserProvider({ children }) {
  const [user, setUser] = useState(staticDefaultUserValue);
  const [menuItems, setMenuItems] = useState([]);
  const [menus, setMenus] = useState([]);
  const [securityGroupMenus, setSecurityGroupMenus] = useState([]);
  const [defaultUserData, setDefaultUserData] = useState(
    staticDefaultUserValue
  );
  const [loading, setLoading] = useState(true);
  const securities = useRef([]);
  const securityMapRef = useRef(null);
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();

  const getUserGroups = async () => {
    try {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken();
      const groups = idToken.payload["cognito:groups"];
      return groups;
    } catch (error) {
      handleErrorNotification(error);
      return null; // Return null or an empty array
    }
  };

  const getSecurityGroupMenus = async () => {
    try {
      const data = await getData("security_group_menus", { status: "Active" });
      return data;
    } catch (error) {
      handleErrorNotification(error);
      return [];
    }
  };

  const getMenus = async () => {
    try {
      const data = await getData("menus", { status: "Active" });
      return data;
    } catch (error) {
      handleErrorNotification(error);
      return [];
    }
  };

  const getMenuItems = async () => {
    try {
      const data = await getData("menu_items", { status: "Active" });
      return data;
    } catch (error) {
      handleErrorNotification(error);
      return [];
    }
  };

  const getPractice = async () => {
    try {
      const data = await getData("practices", {});
      return data;
    } catch (error) {
      handleErrorNotification(error);
      return null;
    }
  };

  const updateMenus = useCallback(async () => {
    try {
      let securityGroupMenus = await getSecurityGroupMenus();
      securityGroupMenus = securityGroupMenus.filter((menu) =>
        securities.current.includes(menu.security_group)
      );
      const securityMap = new Map();
      securityGroupMenus.forEach((security) => {
        if (security.read_permission) {
          securityMap.set(security.menu_id, security.menu);
        }
      });
      securityMapRef.current = securityMap;
      setSecurityGroupMenus(securityGroupMenus);

      let menus = await getMenus();
      menus = menus.filter((menu) =>
        Array.from(securityMap.values()).includes(menu.name)
      );
      menus = menus.sort((a, b) => (a.menu_order < b.menu_order ? -1 : 1));

      setMenus(menus);
      await updateMenuItems();
    } catch (error) {
      handleErrorNotification(error);
    }
  }, []);

  const updateMenuItems = useCallback(async () => {
    try {
      if (!securityMapRef.current || securityMapRef.current.size === 0) return;
      let menuItems = await getMenuItems();
      menuItems = menuItems.filter((menuItem) =>
        Array.from(securityMapRef.current.values()).includes(menuItem.menu)
      );
      const sortedMenuItems = sortMenuItems(menuItems);
      setMenuItems(sortedMenuItems);
    } catch (error) {
      handleErrorNotification(error);
    }
  }, []);

  const updateUserData = useCallback(
    async (currentUser) => {
      try {
        const emailAttribute = currentUser.attributes.email;
        const customAttributes = currentUser.attributes;
        const practice_id = customAttributes["custom:practice_id"];

        const _securities = await getUserGroups();
        const practice = await getPractice();
        if (!practice || !practice[0]) {
          handleErrorNotification(new Error("Practice data is missing"));
          return;
        }
        securities.current = _securities;

        const userDataValues = {
          practice_name: practice[0].name,
          practiceId: practice_id,
          email: emailAttribute,
          firstName: currentUser.attributes.given_name,
          lastName: currentUser.attributes.family_name,
          securities,
        };

        if (JSON.stringify(userDataValues) !== JSON.stringify(user)) {
          setUser(userDataValues);
          setDefaultUserData(userDataValues);
        }
        await updateMenus();
      } catch (error) {
        handleErrorNotification(error);
      }
    },
    [handleErrorNotification, updateMenus, user]
  );

  const setUserData = useCallback(async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await updateUserData(currentUser);
    } catch (error) {
      //handleErrorNotification(error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [updateUserData]);

  const userInfo = useMemo(() => {
    return {
      ...defaultUserData,
      ...user,
      loading,
      menuItems,
      menus,
      securityGroupMenus,
      updateMenus,
      setUserData,
      updateMenuItems,
      setLoading,
    };
  }, [
    defaultUserData,
    menuItems,
    menus,
    securityGroupMenus,
    loading,
    user,
    setUserData,
    updateMenus,
    updateMenuItems,
    setLoading,
  ]);

  useEffect(() => {
    setUserData();
  }, []);

  return (
    <UserContext.Provider value={userInfo}>
      {notificationState.showNotification && (
        <ShowAlert
          severity={notificationState.severity}
          title={notificationState.title}
          message={notificationState.message}
          description={notificationState.description}
          onClose={handleClose}
        />
      )}

      {children}
    </UserContext.Provider>
  );
}

export { UserProvider };
export default UserContext;
