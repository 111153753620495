// Function to validate the test score against the questionnaire criteria
export function validateQuestionnaireScoreRange(
  episodeTestScore,
  questionnaireCriteriaList
) {
  // Destructure necessary properties from episode_test_score
  const { questionnaire_id, score, questionnaire_name } = episodeTestScore;

  // Find the corresponding questionnaire criteria by questionnaire_id
  const criteria = questionnaireCriteriaList.find(
    (criterion) => criterion.questionnaire_id === questionnaire_id
  );

  if (!criteria) {
    // If no matching criteria is found, the function assumes no validation is needed
    return {
      valid: true,
      message: "",
    };
  }

  // Check if the episode score exceeds the maximum allowed score (upper bound check)
  if (score > criteria.max_score) {
    return {
      valid: false,
      message: `The test score entered for ${questionnaire_name} exceeds the maximum allowed score of ${criteria.max_score}. Please review and correct the score before completing the episode.`,
    };
  }

  // Check if the episode score is below the minimum allowed score (lower bound check)
  if (score < criteria.min_score) {
    return {
      valid: false,
      message: `The test score entered for ${questionnaire_name} is below the minimum allowed score of ${criteria.min_score}. Please review and correct the score before completing the episode.`,
    };
  }

  // If the score is within the valid range, return valid
  return {
    valid: true,
    message: "",
  };
}

export function validateQuestionnaireScoreIntervals(data, measureName) {
  // Filter entries related to the selected measure
  const filteredEntries = data.filter(
    (entry) => entry.measure_name === measureName
  );

  // Group entries by questionnaire
  const groupedByQuestionnaire = filteredEntries.reduce((acc, entry) => {
    if (!acc[entry.questionnaire_name]) {
      acc[entry.questionnaire_name] = [];
    }
    acc[entry.questionnaire_name].push(entry);
    return acc;
  }, {});

  // Check each questionnaire for the presence of both Baseline and Final
  const incompleteQuestionnaires = [];
  const baselineOnlyQuestionnaires = [];
  const finalOnlyQuestionnaires = [];
  let hasCompleteQuestionnaire = false;

  Object.entries(groupedByQuestionnaire).forEach(([questionnaire, entries]) => {
    const hasBaseline = entries.some(
      (entry) => entry.questionnaire_score_interval_name === "Baseline"
    );
    const hasFinal = entries.some(
      (entry) => entry.questionnaire_score_interval_name === "Final"
    );

    if (hasBaseline && hasFinal) {
      hasCompleteQuestionnaire = true;
    } else if (!hasBaseline && !hasFinal) {
      incompleteQuestionnaires.push(questionnaire);
    } else if (hasBaseline && !hasFinal) {
      baselineOnlyQuestionnaires.push(questionnaire);
    } else if (!hasBaseline && hasFinal) {
      finalOnlyQuestionnaires.push(questionnaire);
    }
  });

  // If at least one questionnaire is complete, return valid
  if (hasCompleteQuestionnaire) {
    return { valid: true, message: "" };
  }

  // Case 1: No Baseline or Final in any questionnaire
  if (
    incompleteQuestionnaires.length ===
    Object.keys(groupedByQuestionnaire).length
  ) {
    return {
      valid: false,
      message: `Both a Baseline and Final test score for the ${measureName} measure are required to proceed. ${
        incompleteQuestionnaires.length > 0
          ? `The following questionnaires are incomplete: ${incompleteQuestionnaires.join(
              ", "
            )}. `
          : ""
      }Alternatively, you can mark the episode as 'Complete - Missing Test Scores' if no scores are available.`,
    };
  }

  // Case 2: Only missing Final scores
  if (baselineOnlyQuestionnaires.length > 0) {
    return {
      valid: false,
      message: `A Final test score for the ${measureName} measure is required to proceed. The following questionnaires are incomplete: ${baselineOnlyQuestionnaires.join(
        ", "
      )}. Alternatively, you can mark the episode as 'Complete - Missing Test Scores' if no scores are available.`,
    };
  }

  // Case 3: Only missing Baseline scores
  if (finalOnlyQuestionnaires.length > 0) {
    return {
      valid: false,
      message: `A Baseline test score for the ${measureName} measure is required to proceed. The following questionnaires are incomplete: ${finalOnlyQuestionnaires.join(
        ", "
      )}. Alternatively, you can mark the episode as 'Complete - Missing Test Scores' if no scores are available.`,
    };
  }

  return { valid: true, message: "" };
}

export function validateTreatmentPlanSessionCoverage(
  treatmentPlans,
  minimumSessions
) {
  // Scenario 1: No Treatment Plan
  if (!treatmentPlans || treatmentPlans.length === 0) {
    return {
      valid: false,
      message:
        "A treatment plan is required for TMS (Transcranial Magnetic Stimulation) treatment. Please ensure a valid treatment plan is in place before proceeding.",
    };
  }

  // Create an array to track session coverage
  const sessionCoverage = Array(minimumSessions).fill(false);

  // Iterate over all treatment plans
  treatmentPlans.forEach((plan) => {
    const { session_start, session_end } = plan;

    // Mark session ranges as covered
    for (let i = session_start; i <= session_end; i++) {
      if (i >= 1 && i <= minimumSessions) {
        sessionCoverage[i - 1] = true; // Mark session as covered
      }
    }
  });

  // Scenario 3: Check for any gaps in the session coverage
  const hasGaps = sessionCoverage.includes(false);
  if (hasGaps) {
    return {
      valid: false,
      message: `The TMS treatment plan must include a minimum of ${minimumSessions} sessions. Please update the treatment plan to meet this requirement before proceeding.`,
    };
  }

  // Scenario 2: Check if the total number of sessions covered is less than the minimum required
  const totalCoveredSessions = sessionCoverage.filter(
    (session) => session
  ).length;
  if (totalCoveredSessions < minimumSessions) {
    return {
      valid: false,
      message: `The TMS treatment plan must include a minimum of ${minimumSessions} sessions. Please update the treatment plan to meet this requirement before proceeding.`,
    };
  }

  // If all conditions are met, validation passes
  return {
    valid: true,
    message: "",
  };
}

function buildValidationResult(
  isMissingMeasureStatus,
  isRequiredMeasureStatus,
  missingMeasure,
  requiredMeasure
) {
  // Case 1: The required measure does not have complete scores (invalid for "Completed" statuses)
  if (isRequiredMeasureStatus !== "complete") {
    return {
      valid: false,
      message: `${requiredMeasure} scores are missing. The episode cannot be marked as 'Completed - Missing ${missingMeasure} Test Scores' unless valid ${requiredMeasure} scores are provided.`,
    };
  }

  // Case 2: The missing measure has complete scores (this is invalid for "Missing" statuses)
  if (isMissingMeasureStatus === "complete") {
    return {
      valid: false,
      message: `Both ${missingMeasure} and ${requiredMeasure} scores are recorded. The episode should be marked as 'Completed'. Please update the status accordingly.`,
    };
  }

  // Case 3: The missing measure has partial scores (invalid for "Missing" statuses)
  if (isMissingMeasureStatus === "partial") {
    return {
      valid: false,
      message: `Some ${missingMeasure} scores are already recorded. The episode cannot be marked as 'Completed - Missing ${missingMeasure} Test Scores'. Please complete all required scores or select another status.`,
    };
  }

  // Case 4: Missing measure is incomplete, and required measure is complete (this is valid for "Missing" statuses)
  return { valid: true, message: "" };
}

// Function to validate if a measure has all required intervals (e.g., Baseline, Final)
function validateMeasureIntervals(data, measureName, requiredIntervals) {
  const measureEntries = data.filter(
    (entry) => entry.measure_name === measureName
  );

  const groupedEntries = groupByQuestionnaire(measureEntries);

  if (checkForCompleteIntervals(groupedEntries, requiredIntervals)) {
    return "complete";
  }

  if (
    Object.values(groupedEntries).some((entries) =>
      entries.some((entry) =>
        requiredIntervals.includes(entry.questionnaire_score_interval_name)
      )
    )
  ) {
    return "partial";
  }

  return "incomplete";
}

// Function to group entries by questionnaire
function groupByQuestionnaire(entries) {
  return entries.reduce((acc, entry) => {
    if (!acc[entry.questionnaire_name]) {
      acc[entry.questionnaire_name] = [];
    }
    acc[entry.questionnaire_name].push(entry);
    return acc;
  }, {});
}

// Function to check if any questionnaire has all required intervals (Baseline, Final, etc.)
function checkForCompleteIntervals(groupedEntries, requiredIntervals) {
  return Object.values(groupedEntries).some((entries) => {
    return requiredIntervals.every((interval) =>
      entries.some(
        (entry) => entry.questionnaire_score_interval_name === interval
      )
    );
  });
}

// Main validation function that orchestrates the validation for missing and required measures
export function validateMissingAndRequiredMeasureIntervals(
  data,
  missingMeasure,
  requiredMeasure,
  requiredIntervals
) {
  const missingMeasureStatus = validateMeasureIntervals(
    data,
    missingMeasure,
    requiredIntervals
  );

  const requiredMeasureStatus = validateMeasureIntervals(
    data,
    requiredMeasure,
    requiredIntervals
  );

  return buildValidationResult(
    missingMeasureStatus,
    requiredMeasureStatus,
    missingMeasure,
    requiredMeasure
  );
}

export function validateMultipleMeasures(
  data,
  requiredMeasures,
  requiredIntervals
) {
  if (
    !Array.isArray(data) ||
    !Array.isArray(requiredMeasures) ||
    !Array.isArray(requiredIntervals)
  ) {
    throw new Error("Invalid parameters: inputs must be arrays");
  }

  const groupedByMeasure = data.reduce((acc, entry) => {
    const measureKey = entry.measure_name;
    const questionnaireKey = entry.questionnaire_name;

    if (!acc[measureKey]) acc[measureKey] = {};
    if (!acc[measureKey][questionnaireKey])
      acc[measureKey][questionnaireKey] = [];

    acc[measureKey][questionnaireKey].push(entry);
    return acc;
  }, {});

  // First check if all measures are missing or incomplete with details
  const measureDetails = requiredMeasures.map((measure) => {
    const measureData = groupedByMeasure[measure];
    if (!measureData) {
      return {
        measure,
        isIncomplete: true,
        questionnaires: [],
        missingIntervals: requiredIntervals,
      };
    }

    const questionnairesWithMissing = Object.entries(measureData).map(
      ([questionnaire, entries]) => {
        const missing = requiredIntervals.filter(
          (interval) =>
            !entries.some(
              (entry) =>
                entry.questionnaire_score_interval_name.toLowerCase() ===
                interval.toLowerCase()
            )
        );
        return { questionnaire, missingIntervals: missing };
      }
    );

    const hasCompleteQuestionnaire = questionnairesWithMissing.some(
      (q) => q.missingIntervals.length === 0
    );

    return {
      measure,
      isIncomplete: !hasCompleteQuestionnaire,
      questionnaires: questionnairesWithMissing,
    };
  });

  const allMeasuresIncomplete = measureDetails.every((m) => m.isIncomplete);

  if (allMeasuresIncomplete) {
    const allQuestionnaireDetails = measureDetails
      .flatMap(({ questionnaires }) =>
        questionnaires.map(
          (q) =>
            `${q.questionnaire} is missing ${q.missingIntervals.join(
              " and "
            )} scores`
        )
      )
      .join(", ");

    return {
      valid: false,
      message: `Both a Baseline and Final test score for the ${requiredMeasures.join(
        " and "
      )} measures are required to proceed. The following questionnaires are incomplete: ${allQuestionnaireDetails}. Alternatively, you can mark the episode as 'Complete - Missing ${requiredMeasures.join(
        " and "
      )} Test Scores' if no scores are available.`,
    };
  }

  const incompleteMeasures = measureDetails.filter((m) => m.isIncomplete);

  if (incompleteMeasures.length > 0) {
    const details = incompleteMeasures
      .map(
        ({ measure, questionnaires }) =>
          `Both a Baseline and Final test score for the ${measure} measure are required to proceed. The following questionnaires are incomplete: ${questionnaires
            .map(
              (q) =>
                `${q.questionnaire} is missing ${q.missingIntervals.join(
                  " and "
                )} scores`
            )
            .join(
              ", "
            )}. Alternatively, you can mark the episode as 'Complete - Missing ${measure} Test Scores' if no scores are available.`
      )
      .join("\n");

    return {
      valid: false,
      message: details,
    };
  }

  return { valid: true, message: "" };
}
