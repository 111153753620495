// translate to javascript and custom it by Blueberry 03/02/2023
import * as React from "react";
import { Box, FormControlLabel, Stack, Switch } from "@mui/material";
import { tokens } from "../../../theme";
import Header from "../../Header";
import { useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  DataGridPremium,
  GridRowModes,
  GridActionsCellItem,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import DefaultToolbar from "./DefaultToolbar";
import { useEffect, useState } from "react";
import ShowAlert from "../../../utils/ShowAlert";
import { useNotificationHandling } from "../../../utils/NotificationHandling";

function DataEntry({
  title,
  subtitle,
  columns,
  rows,
  loading,
  defaultPageSize,
  onValidateRow,
  onSaveRow,
  onDeleteRow,
  createRowData,
  hideAddIcon = false,
  containerStyle = {},
  boxStyle = {},
  uniqueKey, // Add uniqueKey prop
  disableDelete = false,
  disableEdit = false,
  showInactive,
  onInactiveToggle,
  ...props
}) {
  let userTable = false;
  if (rows.length >= 1) {
    const firstRow = rows[0];
    if ("role_name" in firstRow) {
      userTable = true;
    }
  }

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isDark = theme.palette.mode === "dark";
  const {
    notificationState,
    handleErrorNotification,
    handleSuccessNotification,
    handleClose,
  } = useNotificationHandling();

  const apiRef = useGridApiRef();

  const [internalRows, setInternalRows] = useState(rows);
  const [rowModesModel, setRowModesModel] = useState({});
  const [filterModel, setFilterModel] = useState(loadFilterModel());
  const [rowGroupingModel, setRowGroupingModel] = useState(loadGroupingModel());

  useEffect(() => {
    setInternalRows(rows);
  }, [rows]);

  useEffect(() => {
    saveFilterModel(filterModel);
  }, [filterModel]);

  useEffect(() => {
    return () => {
      saveFilterModel(filterModel); // Ensure filter model is saved when component unmounts
    };
  }, [filterModel]);

  useEffect(() => {
    saveGroupModel(rowGroupingModel);

    return () => {
      saveGroupModel(rowGroupingModel); // Ensure filter model is saved when component unmounts
    };
  }, [rowGroupingModel]);

  function loadFilterModel() {
    const savedFilterModel = localStorage.getItem(`filterModel_${uniqueKey}`);
    if (savedFilterModel) {
      try {
        const parsedModel = JSON.parse(savedFilterModel);
        return parsedModel && parsedModel.items ? parsedModel : { items: [] };
      } catch (e) {
        return { items: [] };
      }
    }
    return { items: [] };
  }

  function loadGroupingModel() {
    const savedGroupModel = localStorage.getItem(`groupModel_${uniqueKey}`);

    if (savedGroupModel) {
      try {
        const parsedModel = JSON.parse(savedGroupModel);
        return parsedModel || [];
      } catch (e) {
        return [];
      }
    }
    return [];
  }

  function saveFilterModel(filterModel) {
    if (filterModel && filterModel.items) {
      localStorage.setItem(
        `filterModel_${uniqueKey}`,
        JSON.stringify(filterModel)
      );
    }
  }

  const saveGroupModel = (groupModel) => {
    localStorage.setItem(`groupModel_${uniqueKey}`, JSON.stringify(groupModel));
  };
  const handleFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
  };

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = async (id) => {
    const row = internalRows.find((r) => r.id === id);

    try {
      const deleteResponse = await onDeleteRow(id, row, internalRows);
      if (deleteResponse === "Deleted") {
        setInternalRows(internalRows.filter((row) => row.id !== id));
      }
      if (row.name !== undefined) {
        handleSuccessNotification(`${row.name} deleted`);
      } else {
        handleSuccessNotification("Deleted");
      }
    } catch (error) {
      handleErrorNotification(error);
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = internalRows.find((row) => row.id === id);
    if (editedRow.isNew) {
      // If its a new row, delete it
      setInternalRows(internalRows.filter((row) => row.id !== id));
    } else {
      // If cancel is clicked set the row back to its old values
      const oldRow = rows.find((row) => row.id === id);
      apiRef.current.updateRows([{ id: oldRow.id, ...oldRow }]);
    }
  };

  const handleProcessRowUpdateError = React.useCallback((error) => {
    handleErrorNotification(error)
  }, [handleErrorNotification]);

  const handleGroupModelChange = (model, details) => {
    setRowGroupingModel(model);
  };

  const processRowUpdate = async (newRow, oldRow) => {
    const updatedRow = { ...newRow };

    if (!updatedRow.isNew) updatedRow.isNew = false;

    // When an error is displayed, this code makes sure the previous input data
    // remains on the row so the user can fix the mistake and not have to re-input
    // all information again
    setInternalRows(
      internalRows.map((row) => (row.id === newRow.id ? updatedRow : row))
    );

    try {
      const validatedRow = await onValidateRow(updatedRow, oldRow);

      const savedRow = await onSaveRow(
        validatedRow.id,
        validatedRow,
        oldRow,
        internalRows
      );
      if (savedRow?.name !== undefined) {
        handleSuccessNotification(`${savedRow.name} saved`);
      } else {
        handleSuccessNotification("Saved");
      }

      // This return statement is required or else datagrid will throw an internal error
      // Cannot read properties of undefined (reading 'id') at getRowIdFromRowModel
      return savedRow;
    } catch (error) {
      // THIS WORKED FAIRLY WELL --- NOT SURE IF ITS REQUIRED
      apiRef.current.updateRows([{ id: updatedRow.id, ...newRow }]);

      throw error;
    }
  };

  function deepEqual(obj1, obj2) {
    if (obj1 === obj2) {
      return true; // If they're the same object or both primitives
    }

    if (typeof obj1 !== "object" || typeof obj2 !== "object") {
      return false; // One is an object, the other isn't
    }

    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false; // Different number of properties
    }

    for (const key in obj1) {
      if (!obj2.hasOwnProperty(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  let appendedColumns = [];
  if (userTable === false) {
    appendedColumns = [...columns];
  } else {
    appendedColumns = [...columns];
  }

  if (!disableDelete || !disableEdit) {
    const actionColumns = {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        const items = [];

        if (!disableEdit) {
          items.push(
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={
                props.type === "form_entry"
                  ? () => props.openModal(id, true)
                  : handleEditClick(id)
              }
              color="inherit"
            />
          );
        }
        if (!disableDelete) {
          items.push(
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => handleDeleteClick(id)}
              color="inherit"
            />
          );
        }
        return items;
      },
    };

    appendedColumns.push(actionColumns);
  }
  //pagination
  const [pageSize, setPageSize] = useState(defaultPageSize);

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  return (
    <Box sx={boxStyle}>
      {(title || subtitle) && <Header title={title} subtitle={subtitle} />}

      <Box
        m="40px 0 0 0"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            fontWeight: 500,
          },
          "& .wrap-column--cell": {
            overflowwrap: "break-all",
            wordwrap: "break-all",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.primary[600],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: isDark ? colors.grey[800] : "transparent",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[600],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: isDark
              ? `${colors.grey[100]} !important`
              : `${colors.grey[500]} !important`,
          },
          ...containerStyle,
        }}
      >
        <DataGridPremium
          rows={internalRows}
          columns={appendedColumns}
          loading={loading}
          autoHeight
          editMode="row"
          apiRef={apiRef}
          rowModesModel={rowModesModel}
          onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
          filterModel={filterModel}
          onFilterModelChange={handleFilterModelChange}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          density="compact"
          slots={{
            toolbar: DefaultToolbar,
          }}
          slotProps={{
            toolbar: {
              rows: internalRows,
              setRows: setInternalRows,
              setRowModesModel,
              columns,
              showInactive,
              onInactiveToggle,
              onClick:
                props.type === "form_entry" ? props.openModal : createRowData,
              type: props.type === "form_entry" ? "form_entry" : "data_entry",
              hideAddIcon: hideAddIcon,
            },
          }}
          rowGroupingModel={rowGroupingModel}
          onRowGroupingModelChange={handleGroupModelChange}
          experimentalFeatures={{ newEditingApi: true }}
          //pagination
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          {...props}
        />
      </Box>
    </Box>
  );
}

DataEntry.defaultProps = {
  initialState: {
    columns: {
      columnVisibilityModel: {
        id: false,
      },
    },
  },
  autoHeight: true,

  //pagination
  pagination: true,
  defaultPageSize: 25,
  rowsPerPageOptions: [5, 10, 25, 50, 100],
};

export default DataEntry;
