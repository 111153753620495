// testScores/TestScoreDataGrid.jsx
// Based on TMS_Protocols
//

import * as React from "react";
import "../../../index.css";
import { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import UserContext from "../../../contexts/UserContext";
import { getData, postData, putData } from "../../../utils/API";
import { typeOfDate } from "../../../utils/ValidationUtils";
import ShowAlert from "../../../utils/ShowAlert";
import { useNotificationHandling } from "../../../utils/NotificationHandling";
import EpisodeTabNavigation from "../EpisodeTabNavigation";
import DataEntryForm from "../../../components/datagrid/dataEntryForm";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import EpisodeTestScoreForm from "./EpisodeTestScoreForm";
import useSecurity from "../../../hooks/use-security";

const title = "Test Scores";
const table = "episode_questionnaire_scores";

export default function EpisodeTestScores() {
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();
  const location = useLocation();
  const { menuItems, securityGroupMenus } = useContext(UserContext);
  const menuItem = menuItems.find(
    (menuItem) => menuItem.menu_component === "PracticeEpisodesDataGrid"
  );
  const { canCreate, canUpdate, canDelete } = useSecurity({
    menuItems,
    pathname: menuItem.menu_route,
    securityGroupMenus,
  });

  const { episodeOfCare } = location.state;
  const episodeOfCareId = episodeOfCare["id"];
  const startDate = episodeOfCare["start_date"];
  const endDate = episodeOfCare["end_date"];
  const patientId = episodeOfCare["patient_id"];
  const subtitle = episodeOfCare.full_name;

  let endDateFound = false;
  const datatypeEndDate = typeof endDate;
  if (
    (datatypeEndDate === "string" && endDate.length === 0) ||
    endDate == null
  ) {
    endDateFound = false;
  } else {
    endDateFound = true;
  }

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedRow, setRow] = useState(null);
  const isUpdate = Boolean(selectedRow);
  const { practiceId } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [rows, setRawRows] = useState([]);
  const [questionnaireObjects, setQuestionnaireObjects] = useState([]);
  const [
    questionnaireScoreIntervalObjects,
    setQuestionnaireScoreIntervalObjects,
  ] = useState([]);
  const [questionnaireCriteriaObjects, setQuestionnaireCriteriaObjects] =
    useState([]);

  const query_params = {
    episode_of_care_id: episodeOfCareId,
  };

  const columns = [
    {
      field: "questionnaire_name",
      headerName: "Questionnaire",
      type: "singleSelect",
      valueOptions: questionnaireObjects.map((question) => question.name),
      editable: true,
      flex: 1.0,
      defaultValue: "",
    },
    {
      field: "questionnaire_score_interval_name",
      headerName: "Score Interval",
      type: "singleSelect",
      valueOptions: questionnaireScoreIntervalObjects
        .filter((score) => score.status === "Active")
        .map((score) => score.name),
      editable: true,
      flex: 0.5,
      defaultValue: "",
    },
    {
      field: "score_date",
      headerName: "Date",
      type: "date",
      width: 180,
      editable: true,
      valueGetter: (params) => {
        const scoreDate = params.row.score_date;
        return typeOfDate(scoreDate, "object");
      },
    },
    {
      field: "score",
      headerName: "Score",
      editable: true,
      defaultValue: 0,
    },
    {
      field: "indication_at_interval",
      headerName: "Indication",
      flex: 0.5,
      defaultValue: "",
    },
    {
      field: "outcome_at_interval",
      headerName: "Outcome",
      flex: 0.5,
      defaultValue: "",
    },
  ];

  const setRows = (rows) => {
    if (!Array.isArray(rows)) {
      return;
    }
    setRawRows(sortRows(rows).map((r, i) => ({ ...r, no: i + 1 })));
  };

  // Sorting function
  const sortRows = (rows) => {
    return rows.sort((a, b) => {
      if (a.questionnaire_name < b.questionnaire_name) return -1;
      if (a.questionnaire_name > b.questionnaire_name) return 1;
      if (a.score_date < b.score_date) return -1; // Ascending order
      if (a.score_date > b.score_date) return 1; // Ascending order
      return 0;
    });
  };

  const deleteRow = async (id, row, oldRows) => {
    const rowToSave = { ...row, deleted: true };

    try {
      await putData(table, rowToSave);
      setRows(oldRows.filter((r) => r.id !== id));
      return "Deleted";
    } catch (error) {
      throw error;
    }
  };

  const handleOpenModal = (id) => {
    if (
      !questionnaireObjects ||
      questionnaireObjects.length === 0 ||
      !questionnaireScoreIntervalObjects ||
      questionnaireScoreIntervalObjects.length === 0 ||
      !questionnaireCriteriaObjects ||
      questionnaireCriteriaObjects.length === 0
    ) {
      const customError = new Error();
      customError.name = "Data Error";
      customError.message = `Data could not be retrieved at this time. Please check your connection and try again, or contact support if the issue persists.`;
      handleErrorNotification(customError);
      return; // Prevent opening the modal
    }

    let selectedRow = null;
    setIsOpenModal(true);
    if (id) {
      selectedRow = rows.find((r) => r.id === id);
      setRow(selectedRow);
    }
  };

  const handleModalClose = () => {
    setIsOpenModal(false);
    if (selectedRow) {
      setRow(null);
    }
  };

  const handleOnSubmit = async (values) => {
    const rowToSave = {
      ...values,
      episode_of_care_id: episodeOfCareId,
      patient_id: patientId,
      practice_id: practiceId,
    };

    handleModalClose();

    try {
      if (!isUpdate) {
        await postData(table, rowToSave);
        getTestScores();
      } else {
        await putData(table, { ...rowToSave, id: selectedRow.id });
        getTestScores();
      }
    } catch (error) {
      handleErrorNotification(error);
    }
  };

  const getTestScores = async function () {
    setLoading(true);

    try {
      const data = await getData(table, query_params);
      setRows(data);
    } catch (error) {
      handleErrorNotification(error);
    }
    setLoading(false);
  };

  const getQuestionnaireScoreIntervals = async () => {
    try {
      let data = await getData("questionnaire_score_intervals");
      data = data.filter((item) => item.status === "Active");
      setQuestionnaireScoreIntervalObjects(data);
    } catch (error) {
      handleErrorNotification(error);
    }
  };

  const getQuestionnaires = async () => {
    try {
      let data = await getData("questionnaires");
      data = data.filter((item) => item.status === "Active");
      setQuestionnaireObjects(data);
    } catch (error) {
      handleErrorNotification(error);
    }
  };

  const getQuestionnaireCriteria = async () => {
    try {
      const data = await getData("questionnaire_criteria");
      setQuestionnaireCriteriaObjects(data);
    } catch (error) {
      handleErrorNotification(error);
    }
  };

  useEffect(() => {
    getTestScores();
    getQuestionnaireScoreIntervals();
    getQuestionnaires();
    getQuestionnaireCriteria();
  }, []);

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  return (
    <div>
      <EpisodeTabNavigation />
      <DataEntryForm
        title={title}
        subtitle={subtitle}
        columns={columns}
        rows={rows}
        filterKey="EpisodeTestScores"
        loading={loading}
        onAdd={handleOpenModal}
        onEdit={handleOpenModal}
        onDeleteRow={deleteRow}
        disableAdd={!canCreate}
        disableEdit={!canUpdate}
        disableDelete={!canDelete}
      />

      <Dialog open={isOpenModal} onClose={handleModalClose}>
        <DialogTitle>{`${isUpdate ? "Edit" : "Add"} Test Score`}</DialogTitle>
        <DialogContent>
          <EpisodeTestScoreForm
            onCancel={handleModalClose}
            onSubmit={handleOnSubmit}
            questionnaireObjects={questionnaireObjects}
            questionnaireScoreIntervalObjects={
              questionnaireScoreIntervalObjects
            }
            questionnaireCriteriaObjects={questionnaireCriteriaObjects}
            selectedRow={selectedRow}
            rows={rows}
            startDate={startDate}
            endDate={endDate}
            endDateFound={endDateFound}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
