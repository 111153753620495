import * as React from "react";
import "../../../index.css";
import { useEffect, useState, useContext } from "react";
import DataEntry from "../../../components/datagrid/dataEntry";
import UserContext from "../../../contexts/UserContext";
import { getData, postData, putData, deleteData } from "../../../utils/API";
import { validateRequiredAttributes } from "../../../utils/ValidationUtils";
import ShowAlert from "../../../utils/ShowAlert";
import { useNotificationHandling } from "../../../utils/NotificationHandling";
import { useLocation } from "react-router-dom";
import useSecurity from "../../../hooks/use-security";

// *************** CUSTOMIZE ************** START
export default function DiagnosisCodesGrid() {
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();
  const { pathname } = useLocation();
  const { menuItems, securityGroupMenus } = useContext(UserContext);
  const { canCreate, canUpdate, canDelete } = useSecurity({
    menuItems,
    pathname,
    securityGroupMenus,
  });

  const title = "Diagnosis Codes";
  let subtitle = `View ${title}`;

  const table = "diagnosis_codes";
  const relatedTable = "disorders";

  const requiredAttributes = [
    "disorder_id",
    "category",
    "code",
    "long_description",
    "status",
  ];
  const attributeNames = [
    "Disorder",
    "Category",
    "Diagnosis Code",
    "Long Description",
    "Status",
  ];

  const [loading, setLoading] = useState(true);
  const [rows, setRawRows] = useState([]);
  const [relatedData, setRelatedData] = useState([]);
  const [relatedObjects, setRelatedObjects] = useState([]);
  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "icd10_code_range",
      headerName: "ICD 10 Range",
      type: "singleSelect",
      valueOptions: relatedData,
      editable: true,
      flex: 0.5,
    },
    {
      field: "category",
      headerName: "Category",
      editable: true,
      cellClassName: "wrapWord",
      flex: 0.5,
    },
    {
      field: "code",
      headerName: "Diagnosis Code",
      editable: true,
      cellClassName: "name-column--cell",
      flex: 0.5,
    },
    {
      field: "long_description",
      headerName: "Long Description",
      editable: true,
      cellClassName: "wrapWord",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      editable: true,
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: ["Active", "Inactive"],
      defaultValueGetter: () => "Active",
      flex: 1,
    },
  ];

  const setRows = (rows) => {
    if (!Array.isArray(rows)) {
      //('setRows received non-array data:', rows);
      return;
    }
    setRawRows(rows.map((r, i) => ({ ...r, no: i + 1 })));
  };

  function createRowData(rows) {
    // IS THIS REDUNDANT, ITS ALSO IN DefaultToolBar
    const newId = Math.floor(100000 + Math.random() * 900000);
    return {
      id: newId,
      code: "",
      disorder_id: "",
      disorder_name: "",
      description: "",
      status: "Active",
    };
  }

  async function validateRow(newRow, oldRow) {
    try {
      validateRequiredAttributes(
        ["icd10_code_range"],
        ["ICD 10 Range"],
        newRow
      );

      if (!oldRow || newRow.icd10_code_range !== oldRow.icd10_code_range) {
        const correspondingObject = relatedObjects.find(
          (obj) => obj.name === newRow.icd10_code_range
        );
        newRow.disorder_id = correspondingObject.id;
      }

      validateRequiredAttributes(requiredAttributes, attributeNames, newRow);
      return newRow;
    } catch (error) {
      throw error;
    }
  }

  async function saveRow(id, row, oldRow, oldRows) {
    try {
      if (row.isNew) {
        const rowToSave = { ...row };
        delete rowToSave.id;
        rowToSave["active"] = "Active";
        const data = await postData(table, rowToSave);
        rowToSave.id = data.data.id;
        setRows(oldRows.map((r) => (r.id === id ? { ...rowToSave } : r)));
        return rowToSave;
      } else {
        await putData(table, row);
        setRows(oldRows.map((r) => (r.id === id ? { ...row } : r)));
        return row;
      }
    } catch (error) {
      setRows(oldRows);
      throw error;
    }
  }

  async function deleteRow(id, row, oldRows) {
    const body = {
      id: row.id,
    };

    try {
      await deleteData(table, body);
      setRows(oldRows.filter((r) => r.id !== id));
      return "Deleted";
    } catch (error) {
      setRows(oldRows);
      throw error;
    }
  }

  const getRelativeRows = async () => {
    try {
      const queryParam = {
        deleted: false,
      };
      const data = await getData(relatedTable, queryParam);
      const relatedData = data.map((obj) => obj.icd10_code_range).sort();
      setRelatedData(relatedData);
      setRelatedObjects(data);
    } catch (error) {
      throw error;
    }
  };

  const getRows = async () => {
    setLoading(true);
    try {
      const queryParam = {
        deleted: false,
      };
      const data = await getData(table, queryParam);
      setRows(data);
    } catch (error) {
      throw error;
    }
    setLoading(false);
  };

  const initialFetch = async () => {
    try {
      await Promise.all([getRows(), getRelativeRows()]);
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initialFetch();
  }, []);

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  return (
    <div>
      <DataEntry
        title={title}
        subtitle={subtitle}
        columns={columns}
        rows={rows}
        uniqueKey="DiagnosisCodes"
        onValidateRow={validateRow}
        onSaveRow={saveRow}
        onDeleteRow={deleteRow}
        createRowData={createRowData}
        loading={loading}
        hideAddIcon={!canCreate}
        disableEdit={!canUpdate}
        disableDelete={!canDelete}
      />
    </div>
  );
}
