import React, { useContext, useEffect, useState } from "react";
import DataEntryForm from "../../../components/datagrid/dataEntryForm";
import ShowAlert from "../../../utils/ShowAlert";
import { useNotificationHandling } from "../../../utils/NotificationHandling";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { getData, postData, putData } from "../../../utils/API";
import PracticeTreatmentStatusForm from "./PracticeTreatmentStatusForm";
import useSecurity from "../../../hooks/use-security";
import { useLocation } from "react-router-dom";
import UserContext from "../../../contexts/UserContext";

const title = "Treatment Status";

const table = "practice_treatment_status";

const PracticeTreatmentStatusGrid = () => {
  const [loading, setLoading] = useState(true);
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();
  const [rows, setRows] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedRow, setRow] = useState(null);
  const isUpdate = Boolean(selectedRow);
  const [treatmentTypes, setTreatmentTypes] = useState([]);
  const [treatmentStatus, setTreatmentStatus] = useState([]);
  const { pathname } = useLocation();
  const { menuItems, securityGroupMenus } = useContext(UserContext);
  const { canCreate, canUpdate, canDelete } = useSecurity({
    menuItems,
    pathname,
    securityGroupMenus,
  });

  const columns = [
    {
      field: "treatment_type",
      headerName: "Treatment Type",
      type: "singleSelect",
      flex: 0.5,
      valueOptions: treatmentTypes
        .filter((tt) => rows.find((row) => row.treatment_type === tt.name))
        .map((tt) => tt.name),
    },
    {
      field: "treatment_status",
      headerName: "Treatment Status",
      type: "singleSelect",
      flex: 0.5,
      valueOptions: treatmentStatus
        .filter((ts) => rows.some((row) => row.treatment_status === ts.name))
        .map((ts) => ts.name),
    },
    {
      field: "status",
      headerName: "Status",
      type: "singleSelect",
      flex: 0.5,
      valueOptions: ["Active", "Inactive"],
    },
  ];

  const handleOpenModal = (id) => {
    if (
      !treatmentTypes ||
      treatmentTypes.length === 0 ||
      !treatmentStatus ||
      treatmentStatus.length === 0
    ) {
      const customError = new Error();
      customError.name = "Data Error";
      customError.message = `Data could not be retrieved at this time. Please check your connection and try again, or contact support if the issue persists.`;
      handleErrorNotification(customError);
      return; // Prevent opening the modal
    }

    let selectedRow = null;
    setIsOpenModal(true);
    if (id) {
      selectedRow = rows.find((r) => r.id === id);
      setRow(selectedRow);
    }
  };

  const handleModalClose = () => {
    setIsOpenModal(false);
    if (selectedRow) {
      setRow(null);
    }
  };

  async function deleteRow(id, row, oldRows) {
    const body = {
      id: row.id,
    };

    try {
      await putData(table, body);
      setRows(oldRows.filter((r) => r.id !== id));
      return "Deleted";
    } catch (error) {
      throw error;
    }
  }

  const getRows = async () => {
    setLoading(true);
    try {
      const response = await getData(table, { deleted: false });
      setRows(response);
    } catch (error) {
      throw error;
    }
    setLoading(false);
  };

  const handleOnSubmit = async (values) => {
    const rowToSave = { ...values };
    setLoading(true);
    handleModalClose();
    try {
      if (!isUpdate) {
        await postData(table, rowToSave);
        await getRows();
      } else {
        await putData(table, { ...rowToSave, id: selectedRow.id });
        await getRows();
      }
    } catch (error) {
      handleErrorNotification(error);
    }
    setLoading(false);
  };

  const getTreatmentTypes = async function () {
    try {
      const data = await getData("treatment_types");
      setTreatmentTypes(data);
    } catch (err) {
      throw err;
    }
  };

  const getTreatmentStatus = async function () {
    try {
      const data = await getData("treatment_status", { deleted: false });
      setTreatmentStatus(data);
    } catch (err) {
      throw err;
    }
  };

  const initFetch = async () => {
    try {
      await Promise.all([getRows(), getTreatmentTypes(), getTreatmentStatus()]);
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initFetch();
  }, []);

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  return (
    <div>
      <DataEntryForm
        title={title}
        columns={columns}
        rows={rows}
        loading={loading}
        onAdd={handleOpenModal}
        onEdit={handleOpenModal}
        onDeleteRow={deleteRow}
        filterKey={"practice_treatment_status"}
        disableAdd={!canCreate}
        disableEdit={!canUpdate}
        disableDelete={!canDelete}
      />
      <Dialog open={isOpenModal} onClose={handleModalClose}>
        <DialogTitle>{`${isUpdate ? "Edit" : "Add"} ${title}`}</DialogTitle>
        <DialogContent sx={{ width: "100%", maxWidth: "500px" }}>
          <PracticeTreatmentStatusForm
            treatmentTypes={treatmentTypes}
            onSubmit={handleOnSubmit}
            onClose={handleClose}
            selectedRow={selectedRow}
            treatmentStatus={treatmentStatus}
            onCancel={handleModalClose}
            rows={rows}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PracticeTreatmentStatusGrid;
