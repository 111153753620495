import React, { useContext, useEffect, useState } from "react";
import EpisodeTabNavigation from "../EpisodeTabNavigation";
import UserContext from "../../../contexts/UserContext";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { getData, postData, putData } from "../../../utils/API";
import { useLocation } from "react-router-dom";
import EpisodeMedicationForm from "./EpisodeMedicationForm";
import { typeOfDate } from "../../../utils/ValidationUtils";
import { useNotificationHandling } from "../../../utils/NotificationHandling";
import ShowAlert from "../../../utils/ShowAlert";
import DataEntryForm from "../../../components/datagrid/dataEntryForm";
import useSecurity from "../../../hooks/use-security";

const table = "episode_medications";

const EpisodeMedications = () => {
  const title = "Episode Medications";
  const location = useLocation();
  const { episodeOfCare } = location.state;
  const episodeOfCareId = episodeOfCare["id"];
  const practiceId = episodeOfCare["practice_id"];
  const { menuItems, securityGroupMenus } = useContext(UserContext);
  const menuItem = menuItems.find(
    (menuItem) => menuItem.menu_component === "PracticeEpisodesDataGrid"
  );
  const { canCreate, canUpdate, canDelete } = useSecurity({
    menuItems,
    pathname: menuItem.menu_route,
    securityGroupMenus,
  });
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [medications, setMedications] = useState([]);
  const [medicationFrequencies, setMedicationFrequencies] = useState([]);
  const [selectedRow, setRow] = useState(null);
  const isUpdate = Boolean(selectedRow?.id);
  const subtitle = episodeOfCare.full_name;
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();
  const columns = [
    {
      field: "trade_name",
      headerName: "Trade Name",
      flex: 3,
      defaultValue: "",
    },
    {
      field: "name",
      headerName: "Frequency",
      flex: 3,
      defaultValue: 0,
    },
    {
      field: "dosage_form",
      headerName: "Dosage form",
      flex: 5,
      defaultValue: "",
    },
    {
      field: "strength",
      headerName: "Strength",
      flex: 3,
      defaultValue: "",
    },
    {
      field: "start_date",
      headerName: "Start",
      type: "date",
      flex: 1.5,
      valueGetter: (params) => {
        const scoreDate = params.row.start_date;
        return typeOfDate(scoreDate, "object");
      },
    },
    {
      field: "end_date",
      headerName: "End",
      type: "date",
      flex: 1.5,
      valueGetter: (params) => {
        const scoreDate = params.row.end_date;
        return typeOfDate(scoreDate, "object");
      },
    },
  ];

  async function deleteRow(id, row, oldRows) {
    const rowToSave = { ...row, deleted: true };

    try {
      await putData(table, rowToSave);
      setRows(oldRows.filter((r) => r.id !== id));
      return "Deleted";
    } catch (error) {
      throw error;
    }
  }

  const openModal = (id) => {
    if (
      !medications ||
      medications.length === 0 ||
      !medicationFrequencies ||
      medicationFrequencies.length === 0
    ) {
      const customError = new Error();
      customError.name = "Data Error";
      customError.message = `Data could not be retrieved at this time. Please check your connection and try again, or contact support if the issue persists.`;
      handleErrorNotification(customError);
      return; // Prevent opening the modal
    }

    let selectedRow = {};
    setIsOpenModal(true);
    if (id) {
      selectedRow = rows.find((r) => r.id === id);
      setRow(selectedRow);
    }
  };

  const getMedications = async () => {
    const query_params = {
      category: "RX",
      episode_of_care_id: episodeOfCareId,
      deleted: false,
    };

    const table = "medications";

    try {
      const res = await getData(table, query_params);
      setMedications(res);
    } catch (error) {
      handleErrorNotification(error);
    }
  };

  const getMedicationFrequency = async () => {
    const query_params = {
      episode_of_care_id: episodeOfCareId,
      deleted: false,
    };

    const table = "medication_frequencies";

    try {
      const res = await getData(table, query_params);
      setMedicationFrequencies(res);
    } catch (error) {
      handleErrorNotification(error);
    }
  };

  const getEpisodeMedications = async () => {
    const query_params = {
      episode_of_care_id: episodeOfCareId,
      deleted: false,
    };

    try {
      let data = await getData(table, query_params);
      data = data.sort((a, b) => b.created_at.localeCompare(a.created_at));
      setRows(data.map((row) => ({...row, deleted_name: row.trade_name})));
    } catch (error) {
      handleErrorNotification(error);
    }
  };

  const handleOnSubmit = async (values) => {
    const rowToSave = {
      ...values,
      practice_id: practiceId,
      episode_of_care_id: episodeOfCareId,
    };

    handleModalClose();

    if (rowToSave.start_date) {
      const startDateString = typeOfDate(values.start_date, "string");
      rowToSave.start_date = startDateString;
    }

    if (rowToSave.end_date) {
      const startDateString = typeOfDate(values.end_date, "string");
      rowToSave.end_date = startDateString;
    }

    try {
      if (isUpdate) {
        rowToSave.id = selectedRow.id;
        await putData(table, rowToSave);
      } else {
        await postData(table, rowToSave);
      }
      getEpisodeMedications();
    } catch (error) {
      handleErrorNotification(error);
    }
  };

  const handleModalClose = () => {
    setIsOpenModal(false);
    if (selectedRow) {
      setRow(null);
    }
  };

  const initializeConnections = async () => {
    try {
      await Promise.all([
        getMedications(),
        getMedicationFrequency(),
        getEpisodeMedications(),
      ]);
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initializeConnections();
  }, []);

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  return (
    <div>
      <EpisodeTabNavigation />
      {loading ? (
        <Stack alignItems={`center`}>
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <DataEntryForm
            title={title}
            subtitle={subtitle}
            columns={columns}
            rows={rows}
            filterKey="EpisodeMedications"
            onDeleteRow={deleteRow}
            onAdd={openModal}
            onEdit={openModal}
            loading={loading}
            disableAdd={!canCreate}
            disableEdit={!canUpdate}
            disableDelete={!canDelete}
          />
          <Dialog open={isOpenModal} onClose={handleModalClose}>
            <DialogTitle>
              {isUpdate ? "Edit Medication" : "Add Medication"}
            </DialogTitle>
            <DialogContent>
              <EpisodeMedicationForm
                selectedMedication={selectedRow}
                medications={medications.filter((medication) => {
                  if (
                    rows.find(
                      (row) =>
                        Number(row.medication_id) === Number(medication.id)
                    )
                  ) {
                    return false;
                  } else {
                    return true;
                  }
                })}
                frequencies={medicationFrequencies}
                onCancel={handleModalClose}
                onSubmit={handleOnSubmit}
              />
            </DialogContent>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default EpisodeMedications;
