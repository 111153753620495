import * as React from "react";
import { useEffect, useState, useContext } from "react";
import UserContext from "../../../contexts/UserContext";
import { getData, postData, putData, deleteData } from "../../../utils/API";
import ShowAlert from "../../../utils/ShowAlert";
import { useNotificationHandling } from "../../../utils/NotificationHandling";
import { useLocation } from "react-router-dom";
import useSecurity from "../../../hooks/use-security";
import DataEntryForm from "../../../components/datagrid/dataEntryForm";
import PracticeTMSCoilsForm from "./PracticeTMSCoilsForm";

const table = "practice_tms_coils";
const sort_1 = "device_manufacturer";
const sort_2 = "device_model_number";
const sort_3 = "coil_model_number";

export default function PracticeTMSCoilsGrid() {
  const { practice_name, menuItems, practiceId, securityGroupMenus } =
    useContext(UserContext);
  const {
    notificationState,
    handleSuccessNotification,
    handleErrorNotification,
    handleClose,
  } = useNotificationHandling();
  const title = practice_name;
  const subtitle = "TMS Coils";
  const { pathname } = useLocation();
  const [practiceTMSDevices, setPracticeTMSDevices] = useState([]);
  const { canCreate, canUpdate, canDelete } = useSecurity({
    menuItems,
    pathname,
    securityGroupMenus,
  });
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [tmsCoils, setTMSCoils] = useState([]);
  const isUpdate = Boolean(selectedRow);
  const [loading, setLoading] = useState(true);
  const [rows, setRawRows] = useState([]);

  const columns = [
    {
      field: "name",
      headerName: "Coil Name",
      flex: 200,
    },
    {
      field: "coil_model_number",
      headerName: "Coil Model",
      flex: 200,
    },
    {
      field: "device_manufacturer",
      headerName: "Device Manufacturer",
      flex: 200,
    },
    {
      field: "device_model_number",
      headerName: "Device Model",
      flex: 200,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: ["Active", "Inactive"],
      defaultValueGetter: () => "Active",
      flex: 200,
    },
  ];

  const setRows = (rows) => {
    if (!Array.isArray(rows)) {
      return;
    }
    setRawRows(rows.map((r, i) => ({ ...r, no: i + 1 })));
  };

  function sortItems(
    items,
    sort_attribute_1,
    sort_attribute_2,
    sort_attribute_3
  ) {
    return items.sort((a, b) => {
      // Primary criterion: sort_attribute_1
      const comparison_1 = a[sort_attribute_1].localeCompare(
        b[sort_attribute_1]
      );

      // If the primary criteria are the same, check sort_attribute_2
      if (comparison_1 === 0) {
        const comparison_2 = a[sort_attribute_2].localeCompare(
          b[sort_attribute_2]
        );

        // If both primary and secondary criteria are the same, check sort_attribute_3
        if (comparison_2 === 0) {
          return a[sort_attribute_3].localeCompare(b[sort_attribute_3]); // Tertiary criterion
        }

        return comparison_2;
      }

      return comparison_1;
    });
  }

  const handleOpenModal = (id) => {
    if (id) {
      const row = rows.find((row) => row.id === id);
      setSelectedRow(row);
      getEpisodeTreatmentPlans(row.tms_coil_id);
    }
    setIsOpenModal(true);
  };

  const handleDeleteRow = async (id) => {
    setLoading(true);
    try {
      await deleteData(table, { id });
      await getRows();
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      setLoading(false);
    }
  };

  const getRows = async () => {
    setLoading(true);
    try {
      const queryParam = {
        deleted: false,
      };

      const data = await getData(table, queryParam);
      const sortedItems = sortItems(data, sort_1, sort_2, sort_3);
      setRows(sortedItems);
    } catch (error) {
      throw error;
    }
    setLoading(false);
  };

  const getPracticeTMSDevices = async () => {
    setLoading(true);
    try {
      const queryParam = {
        deleted: false,
      };
      const data = await getData("practice_tms_devices", queryParam);
      setPracticeTMSDevices(data);
    } catch (error) {
      throw error;
    }
    setLoading(false);
  };

  const getTMSCoils = async () => {
    setLoading(true);
    try {
      const queryParam = {
        status: "Active",
      };
      const data = await getData("tms_coils", queryParam);
      setTMSCoils(data);
    } catch (error) {
      throw error;
    }
    setLoading(false);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);

    if (selectedRow) {
      setSelectedRow(null);
    }
  };

  const handleOnSubmit = async (values) => {
    setLoading(true);
    handleCloseModal();
    try {
      const payload = { ...values, practice_id: practiceId };
      if (!isUpdate) {
        await postData(table, payload);
        await getRows();
        handleSuccessNotification("Success");
      } else {
        await putData(table, { ...payload, id: selectedRow.id });
        await getRows();
        handleSuccessNotification("Updated");
      }
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      setLoading(false);
    }
  };

  const initialFetch = async () => {
    try {
      await Promise.all([getRows(), getPracticeTMSDevices(), getTMSCoils()]);
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      setLoading(false);
    }
  };

  const getEpisodeTreatmentPlans = async (tms_coil_id) => {
    setLoading(true);
    try {
      let practiceTreatmentPlans = await getData(
        "practice_tms_treatment_plans"
      );
      practiceTreatmentPlans = practiceTreatmentPlans.filter(
        (t) => t.tms_coil_id === tms_coil_id
      );

      if (practiceTreatmentPlans.length > 0) {
        const customError = new Error();
        customError.name = "Validation Error";
        customError.message =
          "Coil is already used in treatment plans. Editing the coil is prohibited.";
        throw customError;
      }
    } catch (error) {
      handleErrorNotification(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    initialFetch();
  }, []);

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  return (
    <>
      <DataEntryForm
        title={title}
        subtitle={subtitle}
        columns={columns}
        rows={rows}
        loading={loading}
        uniqueKey="PracticeTMSCoilsGrid"
        onAdd={() => handleOpenModal()}
        onEdit={handleOpenModal}
        onDeleteRow={handleDeleteRow}
        disableAdd={!canCreate}
        disableEdit={!canUpdate}
        disableDelete={!canDelete}
      />

      <PracticeTMSCoilsForm
        open={isOpenModal}
        selectedRow={selectedRow}
        onSubmit={handleOnSubmit}
        onCancel={handleCloseModal}
        tmsDevices={practiceTMSDevices}
        tmsCoils={tmsCoils}
        rows={rows}
        loading={loading}
      />
    </>
  );
}
