import React, { useContext, useEffect, useState } from "react";
import DataEntryForm from "../../../components/datagrid/dataEntryForm";
import ShowAlert from "../../../utils/ShowAlert";
import { useNotificationHandling } from "../../../utils/NotificationHandling";
import {
  getData,
  getUsers,
  patchUserData,
  postUserData,
  putUserData,
} from "../../../utils/API";
import useSecurity from "../../../hooks/use-security";
import { useLocation } from "react-router-dom";
import UserContext from "../../../contexts/UserContext";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import PracticeUserForm from "./PracticeUserForm";

const title = "Users";

const PracticeUserGrid = () => {
  const [loading, setLoading] = useState(true);
  const {
    notificationState,
    handleErrorNotification,
    handleSuccessNotification,
    handleClose,
  } = useNotificationHandling();
  const [rows, setRows] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedRow, setRow] = useState(null);
  const isUpdate = Boolean(selectedRow);
  const { pathname } = useLocation();
  const { menuItems, securityGroupMenus } = useContext(UserContext);
  const { canCreate, canUpdate } = useSecurity({
    menuItems,
    pathname,
    securityGroupMenus,
  });
  const [filteredSecurityGroups, setFilteredSecurityGroups] = useState([]);

  const columns = [
    {
      field: "last_name",
      headerName: "Last Name",
      cellClassName: "name-column--cell",
      headerAlign: "left",
      align: "left",
      flex: 0.5,
    },
    {
      field: "first_name",
      headerName: "First Name",
      cellClassName: "name-column--cell",
      headerAlign: "left",
      align: "left",
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "center",
      align: "center",
      flex: 0.75,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      headerAlign: "center",
      align: "center",
      flex: 0.75,
    },
    {
      field: "Groups",
      headerName: "Groups",
      flex: 0.75,
      renderCell: (params) => {
        const userGroups = params.value;
        return (
          <div style={{ whiteSpace: "pre-line", lineHeight: 2 }}>
            {userGroups ? userGroups.join("\n") : ""}
          </div>
        );
      },
    },
    {
      field: "UserCreateDate",
      headerName: "Created Date",
      type: "date",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      valueGetter: (params) => {
        const value = params.row.UserLastModifiedDate;
        return new Date(value);
      },
    },
    {
      field: "UserLastModifiedDate",
      headerName: "Modified Date",
      type: "date",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      valueGetter: (params) => {
        const value = params.row.UserLastModifiedDate;
        return new Date(value);
      },
    },
    {
      field: "UserStatus",
      headerName: "User Status",
      headerAlign: "center",
      align: "center",
      flex: 0.75,
      renderCell: (params) => (
        <span
          style={{
            color: params.value === "CONFIRMED" ? "green" : "red",
            fontWeight: "bold", // Optional: to make the text bold
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "lock",
      type: "actions",
      width: 150,
      headerName: "Enable",
      headerAlign: "center",
      align: "center",
      getActions: ({ id, row }) => {
        const items = [
          <Button
            variant="contained"
            color={row.Enabled ? "error" : "success"}
            size="small"
            onClick={() => handleToggleLock(id, row)}
            sx={{
              width: 120,
            }}
          >
            {row.Enabled ? "Disable" : "Enable"}
          </Button>,
        ];
        return items;
      },
    },
  ];

  const handleToggleLock = async (id, row) => {
    setLoading(true);
    const isLocked = !row.Enabled;

    try {
      const response = await patchUserData({
        username: id,
        operation: isLocked ? "enable_user" : "disable_user",
      });
      handleSuccessNotification(response.message);
    } catch (error) {
      handleErrorNotification(error);
    }

    await getRows();
    setLoading(false);
  };

  const handleOpenModal = (id) => {
    if (!filteredSecurityGroups || filteredSecurityGroups.length === 0) {
      const customError = new Error();
      customError.name = "Data Error";
      customError.message = `Data could not be retrieved at this time. Please check your connection and try again, or contact support if the issue persists.`;
      handleErrorNotification(customError);
      return; // Prevent opening the modal
    }

    let selectedRow = null;
    setIsOpenModal(true);
    if (id) {
      selectedRow = rows.find((r) => r.id === id);
      setRow(selectedRow);
    }
  };

  const handleModalClose = () => {
    setIsOpenModal(false);
    if (selectedRow) {
      setRow(null);
    }
  };

  const getRows = async () => {
    setLoading(true);
    try {
      const response = await getUsers();
      console.log("ROWS");
      console.log(response);
      setRows(
        response
          .map((r) => ({
            ...r,
            id: r.Username,
            email: r.Attributes[0].Value,
            phone_number: r.Attributes[2].Value,
            first_name: r.Attributes[5].Value,
            last_name: r.Attributes[4].Value,
            groups: r.Groups,
          }))
          .sort((a, b) => {
            const lastNameComparison = a["last_name"].localeCompare(
              b["last_name"]
            );
            if (lastNameComparison !== 0) {
              return lastNameComparison; // Sort by last name
            }
            return a["first_name"].localeCompare(b["first_name"]); // Sort by first name if last names are the same
          })
      );
    } catch (error) {
      throw error;
    }
    setLoading(false);
  };

  const getSecurityGroups = async () => {
    try {
      const response = await getData("security_groups");
      const filteredGroups = response.filter(
        (group) => !group.name.startsWith("System_")
      );
      setFilteredSecurityGroups(filteredGroups);
    } catch (error) {
      throw error;
    }
  };

  const handleOnSubmit = async (values) => {
    const rowToSave = { ...values };
    setLoading(true);
    handleModalClose();
    try {
      if (!isUpdate) {
        await postUserData(rowToSave);
        await getRows();
      } else {
        await putUserData({ ...rowToSave, username: selectedRow.id });
        await getRows();
      }
    } catch (error) {
      handleErrorNotification(error);
    }
    setLoading(false);
  };

  const getRowHeight = (params) => {
    const userGroups = params.model.Groups;
    const groupCount = userGroups?.length || 1;
    return 20 + groupCount * 24;
  };

  const initFetch = async () => {
    try {
      await Promise.all([getRows(), getSecurityGroups()]);
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initFetch();
  }, []);

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  return (
    <div>
      <DataEntryForm
        title={title}
        columns={columns}
        rows={rows}
        loading={loading}
        onAdd={handleOpenModal}
        onEdit={handleOpenModal}
        filterKey={"practice_users"}
        disableAdd={!canCreate}
        disableEdit={!canUpdate}
        disableDelete
        getRowHeight={getRowHeight}
      />
      <Dialog open={isOpenModal} onClose={handleModalClose}>
        <DialogTitle>{`${isUpdate ? "Edit" : "Add"} ${title}`}</DialogTitle>
        <DialogContent sx={{ width: "100%", maxWidth: "500px" }}>
          <PracticeUserForm
            rows={rows}
            selectedRow={selectedRow}
            groups={filteredSecurityGroups}
            onSubmit={handleOnSubmit}
            onClose={handleClose}
            onCancel={handleModalClose}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PracticeUserGrid;
